<template>
  <v-select
    :items="selectableAims"
    v-model="localModel"
    :value="localModel"
    :item-text="displayName"
    :label="label"
    @change="update"
  >
    <template v-slot:prepend-item>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-btn-toggle mandatory dense class="pa-3">
            <v-btn text @click="changeAims('clientAimData')">Client Aims</v-btn>
            <v-btn text @click="changeAims('teamAimData')">Team Aims</v-btn>
            <v-btn text @click="changeAims('painData')">Pain Points</v-btn>
          </v-btn-toggle>

          <v-divider class="mx-3"></v-divider>
        </v-col>
      </v-row>
    </template>

    <template v-slot:item="aim" v-if="displayName === 'Value'">
      <div class="pt-3">
        <h3>{{ aim.item.Value }}</h3>
        <p>{{ truncateString(aim.item.Description) }}</p>
      </div>
    </template>
    <template v-slot:item="aim" v-else-if="displayName === 'Name'">
      <div class="pt-3">
        <h3>{{ aim.item.Name }}</h3>
        <p>{{ truncateString(aim.item.Symptoms) }}</p>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ["label", "dialog", "model"],
  data: () => ({
    localModel: null,
    displayName: null,
    aimData: [],
    aims: {
      clientAimData: [],
      teamAimData: [],
      painData: [],
    },
    selectableAims: [],
  }),

  methods: {
    changeAims(index) {
      this.selectableAims = this.aims[index];
      if (index == "painData") {
        this.displayName = "Name";
      } else {
        this.displayName = "Value";
      }
    },

    getData() {
      this.aims.teamAimData = this.getTeamAims;
      this.aims.clientAimData = this.getClientAims;
      this.aims.painData = this.getPainPoints;
    },

    prepopulate() {
      if (this.model) {
        let data = null;
        let location = null;
        for (let array in this.aims) {
          for (let aim in this.aims[array]) {
            if (
              this.aims[array][aim].Value == this.model ||
              this.aims[array][aim].Name == this.model
            ) {
              data = this.aims[array][aim];
              location = array;
              break;
            }
          }
        }

        this.changeAims(location);

        this.localModel = data;
      } else this.changeAims("clientAimData");
    },

    update() {
      this.$emit("update:model", this.localModel);
    },

    truncateString(val) {
      if (val) {
        if (val.length > 80) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
  },

  watch: {
    reset: {
      handler() {
        if (this.reset) {
          this.localModel = null;
          this.$emit("update:model", "");
        }
      },
    },

    dialog: {
      handler(newValue) {
        this.done = newValue;
      },
      immediate: true,
    },
  },

  computed: {
    getTeamAims: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.TeamAims;
      },
    },
    getClientAims: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.ClientAims;
      },
    },
    getPainPoints: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.PainPoints;
      },
    },
  },

  mounted() {
    this.getData();
    this.aimData = this.clientAimData + this.teamAimData + this.painData;
    this.prepopulate();
  },
};
</script>

