var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Has the client provided GIS / Geospatial requirements ? ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.GISRequirements, 'GISRequirements', false)}},model:{value:(_vm.GISRequirements),callback:function ($$v) {_vm.GISRequirements=$$v},expression:"GISRequirements"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Ensure you have a GIS competant person available to discuss the GIS related questions. See 'Learn More' for local GIS reps.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/sites/Atlas/SitePages/Governance.aspx?web=1"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.GISRequirements == 'true')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Please add a link ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{on:{"change":function($event){return _vm.updateStore(_vm.GISRequirementsTrueA, 'GISRequirementsTrueA', false)}},model:{value:(_vm.GISRequirementsTrueA),callback:function ($$v) {_vm.GISRequirementsTrueA=$$v},expression:"GISRequirementsTrueA"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Have you reviewed and understood the GIS standards required by client ? ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.GISRequirementsTrueB, 'GISRequirementsTrueB', false)}},model:{value:(_vm.GISRequirementsTrueB),callback:function ($$v) {_vm.GISRequirementsTrueB=$$v},expression:"GISRequirementsTrueB"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1256888243)},[_c('span',[_vm._v("From a planning, resource and cost aspect it is important to appreciate the significant impact the selection of a standard can have on the amount of work to achieve the GIS deliverables.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/sites/DSN_DTC/SitePages/Geospatial-Standards.aspx/"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.GISRequirementsTrueB == 'true')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Select Type ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":['ISO', 'Client', 'Arup'],"label":""},on:{"change":function($event){return _vm.updateStore(
                _vm.GISRequirementsTrueBTrue,
                'GISRequirementsTrueBTrue',
                false
              )}},model:{value:(_vm.GISRequirementsTrueBTrue),callback:function ($$v) {_vm.GISRequirementsTrueBTrue=$$v},expression:"GISRequirementsTrueBTrue"}})],1)],1)],1):_vm._e(),(_vm.GISRequirementsTrueB == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign an action to agree ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
                _vm.GISRequirementsTrueBFalse,
                'Review the GIS standards required by the client'
              );
              _vm.updateStore(
                _vm.GISRequirementsTrueBFalse,
                'GISRequirementsTrueBFalse',
                true
              );}},model:{value:(_vm.GISRequirementsTrueBFalse),callback:function ($$v) {_vm.GISRequirementsTrueBFalse=$$v},expression:"GISRequirementsTrueBFalse"}})],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.GISRequirements == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign a team member to speak to the client to scope out requirements ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.GISRequirementsFalse,
              'Speak with client to scope GIS / Geospatial requirements'
            );
            _vm.updateStore(_vm.GISRequirementsFalse, 'GISRequirementsFalse', true);}},model:{value:(_vm.GISRequirementsFalse),callback:function ($$v) {_vm.GISRequirementsFalse=$$v},expression:"GISRequirementsFalse"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1256888243)},[_c('span',[_vm._v("This person would ideally be the Digital Facilitator or someone who understands GIS.")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }