<template>
  <v-container>
    <DigitalLandscapeExpansionPanel />
  </v-container>
</template>

<script>
import DigitalLandscapeExpansionPanel from "@/components/DigitalLandscapeExpansionPanel.vue";

export default {
  name: "App",
  props: {},
  components: {
    DigitalLandscapeExpansionPanel,
  },

  data: () => ({
    selectedList: [],
  }),
  computed: {},
  mounted() {},

  methods: {},
};
</script>

<style scoped></style>
