var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"2.4"}},[_c('v-select',{attrs:{"label":"Theme","height":"10px","outlined":"","items":['Digital processes', 'Platform', 'Taxonomy', 'Tool library']},on:{"change":function($event){return _vm.addFilter(_vm.themes, 'theme')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){_vm.removeFilter('theme');
              _vm.themes = [];}}},[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.themes),callback:function ($$v) {_vm.themes=$$v},expression:"themes"}})],1),_c('v-col',{attrs:{"cols":"2.4"}},[_c('v-select',{attrs:{"label":"Function","height":"10px","chips":"","outlined":"","items":['Data management', 'Workflow orchestration']},on:{"change":function($event){return _vm.addFilter(_vm.functions, 'function')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){_vm.removeFilter('function');
              _vm.functions = [];}}},[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.functions),callback:function ($$v) {_vm.functions=$$v},expression:"functions"}})],1),_c('v-col',{attrs:{"cols":"2.4"}},[_c('v-select',{attrs:{"label":"Output","height":"10px","chips":"","outlined":"","items":['Calculations', 'Reporting']},on:{"change":function($event){return _vm.addFilter(_vm.outputs, 'output')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){_vm.removeFilter('output');
              _vm.outputs = [];}}},[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.outputs),callback:function ($$v) {_vm.outputs=$$v},expression:"outputs"}})],1),_c('v-col',{attrs:{"cols":"2.4"}},[_c('v-select',{attrs:{"label":"Role","height":"10px","chips":"","outlined":"","items":[
          'Digital Practitioner',
          'Engineer/Designer',
          'Leader',
          'Project Manager/Director' ]},on:{"change":function($event){return _vm.addFilter(_vm.roles, 'role')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){_vm.removeFilter('role');
              _vm.roles = [];}}},[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}})],1),_c('v-col',{attrs:{"cols":"2.4"}},[_c('v-select',{attrs:{"label":"Communities","height":"50px","chips":"","outlined":"","items":['BIM']},on:{"change":function($event){return _vm.addFilter(_vm.communities, 'community')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){_vm.removeFilter('community');
              _vm.communities = [];}}},[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.communities),callback:function ($$v) {_vm.communities=$$v},expression:"communities"}})],1)],1),_c('v-row',[(_vm.cardsInView.length > 0)?_c('slider',{ref:"slider",staticClass:"pb-16",attrs:{"options":{ slidesToScroll: 4 }}},_vm._l((_vm.cardsInView),function(item,index){return _c('slideritem',{key:index,staticClass:"mt-5 px-2",style:({
          width: '23.5%',
        })},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"hover":"","min-height":"320px","min-width":"100%"}},[_c('v-img',{attrs:{"height":"150","src":item.src}}),_c('v-divider'),_c('p',{staticClass:"text-overline mb-0"},[_vm._v(" "+_vm._s(item.category[0])+" ")]),_c('v-card-title',{staticClass:"justify-center py-2",staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('a',_vm._g(_vm._b({staticStyle:{"text-decoration":"none","color":"grey"},attrs:{"href":item.link,"target":"_blank"}},'a',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)])]}}],null,true)},[_c('span',[_vm._v("Go to website")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"20em"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.info))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.createNewEntry(item.title)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Assign")])])],1)],1)],1)}),1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"my-5",attrs:{"outlined":"","min-height":"20em"}},[_c('div',{staticClass:"my-16"},[_c('v-img',{staticClass:"text-center mb-n3"},[_c('v-icon',{staticClass:"text-center justify-center",attrs:{"x-large":""}},[_vm._v("mdi-exclamation")])],1),_c('v-card-title',{staticClass:"justify-center"},[_c('div',{staticStyle:{"color":"gray"}},[_vm._v("No Product Found")])]),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v("Try changing your selected filters")])],1)])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},on:{"click:outside":function($event){return _vm.cancel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.cancel()}},model:{value:(_vm.addToolDialog),callback:function ($$v) {_vm.addToolDialog=$$v},expression:"addToolDialog"}},[_c('v-card',{staticClass:"removeScroll"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v("Add Automation Tool")]),_c('v-container',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Selected tool","value":_vm.selectedTool.Tool}})],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":"Email tool owner?"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-n16",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("This will notify the region-specific owner of the selected tool")])])]},proxy:true}]),model:{value:(_vm.notifyToolOwner),callback:function ($$v) {_vm.notifyToolOwner=$$v},expression:"notifyToolOwner"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.addToolDialog)?_c('AimSelect',{attrs:{"model":_vm.selectedTool.Aim,"dialog":_vm.selectedTool.Aim,"label":"Potential use case"},on:{"update:model":function($event){return _vm.$set(_vm.selectedTool, "Aim", $event)},"update:dialog":function($event){return _vm.$set(_vm.selectedTool, "Aim", $event)}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.addToolDialog)?_c('StaffSelect',{attrs:{"display":true,"model":_vm.selectedTool.TeamOwner,"dialog":_vm.selectedTool.TeamOwner,"label":"Search for Team owner"},on:{"update:model":function($event){return _vm.$set(_vm.selectedTool, "TeamOwner", $event)},"update:dialog":function($event){return _vm.$set(_vm.selectedTool, "TeamOwner", $event)}}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"mb-10",attrs:{"filled":"","no-resize":"","label":"Notes (optional)","rows":"2"},model:{value:(_vm.selectedTool.Notes),callback:function ($$v) {_vm.$set(_vm.selectedTool, "Notes", $$v)},expression:"selectedTool.Notes"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"color":"blue","text":"","disabled":_vm.selectedTool.TeamOwner.Name == undefined ||
                _vm.selectedTool.Aim == undefined},on:{"click":_vm.addTool}},[_vm._v("Add tool")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }