<template>
  <v-btn @click="printState()"> check state </v-btn>
</template>

<script>
export default {
  data: () => ({}),
  mounted() {},

  methods: {
    printState() {
      console.log(JSON.stringify(this.$store.getters.plan.DigitalPlan));
    },
  },
};
</script>
