<template>
  <v-row justify="center">
    <v-expansion-panels accordion v-model="panels" multiple>
      <v-expansion-panel v-for="(item, i) in headers" :key="i">
        <v-expansion-panel-header color="secondary">
          {{ item }}</v-expansion-panel-header
        >

        <v-expansion-panel-content v-if="item == 'BIM'">
          <BIM />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item == 'GIS'">
          <GIS />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item == 'Product Development'">
          <ProductDevelopment />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
// import ArupResources from "@/components/ArupResources.vue";
import BIM from "@/components/BIM.vue";
import GIS from "@/components/GIS.vue";
import ProductDevelopment from "@/components/ProductDevelopment.vue";

export default {
  components: {
    GIS,
    ProductDevelopment,
    BIM,
  },
  data: () => ({
    panels: [],
    items: 6,
    headers: [],
  }),
  created() {
    this.all();
    this.headers = this.$store.state.plan.DigitalPlan.ProjectSpecificChoices;
  },
  mounted() {
    this.headers = this.$store.state.plan.DigitalPlan.ProjectSpecificChoices;
  },
  methods: {
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
  },
};
</script>
