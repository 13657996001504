<template>
  <v-container>
    <v-row>
      <v-col cols="2.4">
        <v-select
          v-model="themes"
          label="Theme"
          height="10px"
          outlined
          @change="addFilter(themes, 'theme')"
          :items="['Digital processes', 'Platform', 'Taxonomy', 'Tool library']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('theme');
                themes = [];
              "
              >{{ item }}
            </v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="functions"
          label="Function"
          height="10px"
          chips
          outlined
          @change="addFilter(functions, 'function')"
          :items="['Data management', 'Workflow orchestration']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('function');
                functions = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="outputs"
          label="Output"
          height="10px"
          chips
          outlined
          @change="addFilter(outputs, 'output')"
          :items="['Calculations', 'Reporting']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('output');
                outputs = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="roles"
          label="Role"
          height="10px"
          chips
          outlined
          @change="addFilter(roles, 'role')"
          :items="[
            'Digital Practitioner',
            'Engineer/Designer',
            'Leader',
            'Project Manager/Director',
          ]"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('role');
                roles = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="communities"
          label="Communities"
          height="50px"
          chips
          outlined
          @change="addFilter(communities, 'community')"
          :items="['BIM']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('community');
                communities = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>
    </v-row>

    <v-row>
      <slider
        ref="slider"
        :options="{ slidesToScroll: 4 }"
        v-if="cardsInView.length > 0"
        class="pb-16"
      >
        <slideritem
          v-for="(item, index) in cardsInView"
          :key="index"
          class="mt-5 px-2"
          :style="{
            width: '23.5%',
          }"
        >
          <v-card
            hover
            class="d-flex flex-column"
            min-height="320px"
            min-width="100%"
          >
            <v-img height="150" :src="item.src"></v-img>

            <v-divider></v-divider>
            <p class="text-overline mb-0">
              {{ item.category[0] }}
            </p>

            <v-card-title
              class="justify-center py-2"
              style="word-break: normal"
            >
              {{ item.title }}
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon
                    ><a
                      :href="item.link"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                      style="text-decoration: none; color: grey"
                      ><v-icon>mdi-link</v-icon></a
                    ></v-btn
                  >
                </template>
                <span>Go to website</span>
              </v-tooltip>
              <v-tooltip bottom max-width="20em">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    ></v-btn
                  >
                </template>
                <span>{{ item.info }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="createNewEntry(item.title)"
                    ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
                  ></template
                ><span>Assign</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </slideritem>
      </slider>

      <v-row v-else>
        <v-col cols="12">
          <v-card outlined min-height="20em" class="my-5">
            <div class="my-16">
              <v-img class="text-center mb-n3">
                <v-icon x-large class="text-center justify-center"
                  >mdi-exclamation</v-icon
                ></v-img
              >
              <v-card-title class="justify-center">
                <div style="color: gray">No Product Found</div>
              </v-card-title>
              <v-card-subtitle class="text-center"
                >Try changing your selected filters</v-card-subtitle
              >
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-dialog v-model="notifyText" hide-overlay width="300px"
        ><v-alert type="info" dense dismissible
          >An email has been sent to the tool owner</v-alert
        ></v-dialog
      > -->
    </v-row>

    <!-- <v-btn @click="debug()">debug</v-btn> -->

    <v-dialog
      v-model="addToolDialog"
      max-width="800px"
      @click:outside="cancel()"
      @keydown.escape="cancel()"
    >
      <v-card class="removeScroll">
        <v-card-title class="headline grey lighten-2"
          >Add Automation Tool</v-card-title
        >

        <v-container class="pa-5">
          <v-row>
            <v-col cols="6">
              <v-text-field
                disabled
                label="Selected tool"
                :value="selectedTool.Tool"
              ></v-text-field>
            </v-col>
            <v-col class="ml-2" cols="4">
              <v-checkbox v-model="notifyToolOwner" label="Email tool owner?">
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on" class="ml-n16"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span
                      >This will notify the region-specific owner of the
                      selected tool</span
                    >
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AimSelect
                v-if="addToolDialog"
                :model.sync="selectedTool.Aim"
                :dialog.sync="selectedTool.Aim"
                label="Potential use case"
              />
            </v-col>
            <v-col cols="6">
              <StaffSelect
                v-if="addToolDialog"
                :display="true"
                :model.sync="selectedTool.TeamOwner"
                :dialog.sync="selectedTool.TeamOwner"
                label="Search for Team owner"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mb-10"
                filled
                no-resize
                v-model="selectedTool.Notes"
                label="Notes (optional)"
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6" align="right">
              <v-btn text @click="cancel">cancel</v-btn>
              <v-btn
                color="blue"
                text
                :disabled="
                  selectedTool.TeamOwner.Name == undefined ||
                  selectedTool.Aim == undefined
                "
                @click="addTool"
                >Add tool</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import { slider, slideritem } from "vue-concise-slider";
import StaffSelect from "./StaffSelect.vue";
import AimSelect from "./AimSelect.vue";

export default {
  props: {},
  components: { slider, slideritem, StaffSelect, AimSelect },
  data: () => ({
    addToolDialog: false,

    selectedTool: {
      Id: null,
      Tool: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },

    themes: [],
    functions: [],
    outputs: [],
    roles: [],
    communities: [],

    activeFilters: {
      theme: null,
      function: null,
      output: null,
      role: null,
      community: null,
    },

    notifyText: false,
    notifyToolOwner: false,

    cardsInView: [],
    cardList: [
      {
        title: "ArupCompute",
        link: "https://compute.arup.digital/library",
        category: [
          "Tool library ",
          "Calculations",
          "Digital Practitioner",
          "Engineer/Designer",
        ],
        info: "ArupCompute is a single, standardised, web-based platform for hosting and running our custom engineering processes. ",
        src: "/img/arupcompute.png",
        owner: "jenessa.man@arup.com",
      },
      {
        title: "Arup's Digital Maturity Measure",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Digital-Maturity-Measure.aspx?web=1",
        category: ["Leader", "Project Manager/Director", "Reporting"],
        info: "The Digital Maturity Measure is our tool to assess our projects' digital delivery. It is completed by all applicable fee earning projects and assesses what projects do. Analysing its data will identify strengths, weaknesses and trends to support the development of consistent digital delivery.",
        src: "/img/maturitymeasure.png",
        owner: "paul.hill@arup.com",
      },
      {
        title: "Automated Reporting",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Automated-reporting.aspx?web=1",
        category: [
          "Digital processes",
          "Workflow orchestration",
          "Digital Practitioner",
          "Leader",
          "Project Manager/Director",
        ],
        info: "HotDocs & K2 are an integrated Document Automation solution you can use on your task or project today",
        src: "/img/automatedreporting.png",
        owner: "yun.sung@arup.com",
      },
      {
        title: "BIM to BAM",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/BIM-to-BAM.aspx?web=1",
        category: ["Engineer/Designer", "BIM"],
        info: "BIM to BAM automates the Buildings design process, more specifically the heating & cooling load calculations, which are required for most mechanical design scopes.",
        src: "/img/bimtobam.png",
        owner: {
          EU: "karsten.jurkait@arup.com",
          UKIMEA: "jaco.kemp@arup.com",
          EA: "jaco.kemp@arup.com",
          AMS: "ben.brannon@arup.com",
          AUS: "ben.brannon@arup.com",
        },
      },
      {
        title: "BuildingSmart International",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/BuildingSmart-International.aspx?web=1",
        category: ["Taxonomy"],
        info: "BuildingSmart International (BSi) is a long established, not for profit organisation and the originator and governor of open BIM and the international file exchange standard IFC and associated construct the Data Dictionary, where elements are defined.",
        src: "/img/buildingsmart.png",
        owner: "martin.simpson@arup.com",
      },
      {
        title: "Consulting Foundations",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Global-Consulting-Taxonomy.aspx?web=1",
        category: ["Taxonomy", "Digital Practitioner"],
        info: "A taxonomy and schema for the various data types will help create more  value from insights for clients and enable new Arup advisory services off the back of design work",
        src: "/img/consultingfoundations.png",
        owner: "rita.lavasa@arup.com",
      },
      {
        title: "DesignCheck",
        link: "https://designcheck.arup.com/",
        category: ["Tool library", "Calculations", "Digital Practitioner"],
        info: "DesignCheck is a library of engineering calculations written in code - calculations that are easy to automate, check, and share.",
        src: "/img/designcheck.png",
        owner: {
          AMS: ["jenessa.man@arup.com", "Evan.Dinelli@arup.com"],
          AUS: ["elise.verrocchi@arup.com", "Geoffrey.Iwasa@arup.com"],
          EU: ["tilman.reinhardt@arup.com", "Joost.Gevaert@arup.com"],
          EA: "gilang.judhinaputra@arup.com",
          UKIMEA: ["ben.clayton@arup.com", "Christopher.Noble@arup.com"],
        },
      },
      {
        title: "Digital Design Brief",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Digital-Design-Brief.aspx?web=1",
        category: [
          "Platform",
          "Data management",
          "Digital Practitioner",
          "Engineer/Designer",
          "Project Manager/Director",
        ],
        info: "The Digital Design Brief project's mission is to create a robust framework for design brief data​ that provides a single source of truth on our projects.",
        src: "/img/DDB.png",
        owner: {
          UKIMEA: "fraser.plumb@arup.com",
          EU: "fraser.plumb@arup.com",
          AUS: "fraser.plumb@arup.com",
          EA: "yun.sung@arup.com",
          AMS: "jeffrey.russell@arup.com",
        },
      },
      {
        title: "EnergyBox",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/EnergyBox.aspx?web=1",
        category: ["coming soon"],
        info: "Coming soon...",
        src: "/img/energybox.png",
        owner: "jaco.kemp@arup.com",
      },
      {
        title: "Fuse",
        link: "https://www.fuse.arup.com/",
        category: ["Leader", "Project Manager/Director"],
        info: "Fuse helps you build and publish secure, customisable and flexible websites for your projects that integrate rich and interactive content from commonly used tools, applications and services.",
        src: "/img/fuse.png",
        owner: {
          UKIMEA: "ian.wise@arup.com",
          EU: "ian.wise@arup.com",
          AMS: "alexa.hinves@arup.com",
          EA: "thomas.bush@arup.com",
          AUS: "donny.darmawan@arup.com",
        },
      },
      {
        title: "Global Revit Standards",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Global-Revit-Standards.aspx?web=1",
        category: ["Taxonomy"],
        info: [
          "The Global Revit Standard sets globally consistent modelling, content, templates, and data standards across all ARUP projects worldwide.",
        ],
        src: "/img/globalrevitstandards.png",
        owner: {
          UKIMEA: "david.seager@arup.com",
          AMS: "ed.paul@arup.com",
          EU: "francesco.cuda@arup.com",
          AUS: "marcos.watts@arup.com",
          EA: "callum.hulme@arup.com",
        },
      },
      {
        title: "Global Taxonomy",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Global-Taxonomy.aspx?web=1",
        category: ["Taxonomy", "Digital Practitioner"],
        info: "A universal register for taxonomies (or naming protocols), that is accessible to engineers and digital users alike all around the world.",
        src: "/img/globaltaxonomy.png",
        owner: "jaco.kemp@arup.com",
      },
      {
        title: "Global Tekla Standards",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Global-Tekla-Standards.aspx?web=1",
        category: ["Taxonomy"],
        info: "evelopment of a Global Tekla Standard (GTS) that will drive quality, consistency, and reliability of project deliverables for any global, regional, or local requirement throughout Arup.",
        src: "/img/globalteklastandards.png",
        owner: ["clayton.riddle@arup.com", "neil.robertson@arup.com"],
      },
      {
        title: "Infrastructure Foundations",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Global-Infrastructure-Taxonomy.aspx?web=1",
        category: ["Taxonomy", "Digital Practitioner"],
        info: "The Infrastructure Foundations automation task supports the transformation of the delivery of projects in the Transport and EWR portfolios, delivering tangible cost savings and efficiency gains through automation and driving quality, consistency, and reliability.",
        src: "/img/infrastructurefoundations.png",
        owner: "oliver.riches@arup.com",
      },
      {
        title: "Low-code automation",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Low-code-Automation.aspx?web=1",
        category: [
          "Digital Processes",
          "Data Management",
          "Workflow Orchestration",
          "Digital Practitioner",
        ],
        info: "To promote 'citizen-developer' automation of common tasks using the MS Power Platform of low-code tools and solutions",
        src: "/img/lowcodeautomation.png",
        owner: "rich.synott@arup.com",
      },
      {
        title: "Robotic Process Automation",
        link: "https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Robotic-Process-Automation.aspx?web=1",
        category: ["Workflow orchestration"],
        info: "Robotic Process Automation (RPA) is a form of business process automation technology, which uses software ‘robots’ to perform a defined set of actions based on programmed business rules.",
        src: "/img/roboticprocessautomation.png",
        owner: "",
      },
      {
        title: "Speckle",
        link: "https://speckle.arup.com/",
        category: ["Digital Practitioner"],
        info: "Speckle connects different design software in a way that makes the data available to Arup’s cloud-based tooling and custom project scripts. Interoperability between our design software and our custom workflows.",
        src: "/img/speckle.png",
        owner: {
          EU: [
            "joost.schlebaum@arup.com",
            "guus.gooskens@arup.com",
            "jeroen.debruijn@arup.com",
            "jenessa.man@arup.com",
          ],
          UKIMEA: [
            "fernando.ruiz-barberan@arup.com",
            "jeroen.debruijn@arup.com",
            "jenessa.man@arup.com",
          ],
          EA: [
            "yun.sung@arup.com",
            "jeroen.debruijn@arup.com",
            "jenessa.man@arup.com",
          ],
          AUS: [
            "elise.verrocchi@arup.com",
            "jeroen.debruijn@arup.com",
            "jenessa.man@arup.com",
          ],
          AMS: [
            "jeffrey.russell@arup.com",
            "jeroen.debruijn@arup.com",
            "jenessa.man@arup.com",
          ],
        },
      },
      {
        title: "Tools and Workflows",
        link: "https://arup.sharepoint.com/sites/tools-register/SitePages/ToolsRegister.aspx#/tools-list?gridState={%22sortColumn%22:%22modified%22,%22sortDirection%22:%22desc%22,%22dataOffset%22:0,%22viewMode%22:0,%22toolType%22:[],%22status%22:[]}",
        category: [
          "Reporting",
          "Tool library",
          "Digital processes",
          "Digital Practitioner",
          "Engineer/Designer",
        ],
        info: "Tools and Workflows is the Arup library of technical software (developed externally or internally) and workflow maps that we use to design, engineer, plan, document and generally deliver our work.",
        src: "/img/toolsandworkflows.png",
        owner: "adam.pope@arup.com",
      },
      {
        title: "TotalDesign Automation Platform",
        link: "https://tda.arup.com/",
        category: [
          "Workflow orchestration",
          "Digital Practitioner",
          "Engineer/Designer",
        ],
        info: "Arup's platform for design and engineering apps, which are linked together via data to deliver projects.",
        src: "/img/TDAplatform.png",
        owner: ["ivy.graham@arup.com", "steven.downing@arup.com"],
      },
    ],

    //   fakeCardList: [ // use for testing in dev without bothering real people!
    //     {
    //       title: "James's Global Misfortune",
    //       link: "https://letmegooglethat.com/?q=what+is+the+date+today",
    //       category: [],
    //       info: "This is a global solution to all the world's problems",
    //       src: "/img/toolsandworkflows.png",
    //       owner: {
    //         UKIMEA: "rio.aroun-maxwell@arup.com",
    //         EU: "emily.tinney.arup.com",
    //         AUS: "rio.aroun-maxwell@arup.com",
    //         AMS: ["rioamaxwell@gmail.com", "mitchell.harvey@arup.com"],
    //         EA: "james.odonnell@arup.com",
    //       },
    //     },
    //     {
    //       title: "Rio's Global Solution",
    //       link: "https://letmegooglethat.com/?q=what+is+the+date+today",
    //       category: [],
    //       info: "This is a global solution to all the world's problems",
    //       src: "/img/toolsandworkflows.png",
    //       owner: {
    //         UKIMEA: "rio.aroun-maxwell@arup.com",
    //         EU: "emily.tinney.arup.com",
    //         AMS: "rio.aroun-maxwell@arup.com",
    //         AUS: ["rioamaxwell@gmail.com", "mitchell.harvey@arup.com"],
    //         EA: "james.odonnell@arup.com",
    //       },
    //     },
    //     {
    //       title: "Mitchell's Global Destruction",
    //       link: "https://letmegooglethat.com/?q=what+is+the+date+today",
    //       category: [],
    //       info: "This is a global solution to all the world's problems",
    //       src: "/img/toolsandworkflows.png",
    //       owner: ["ben.hussey@arup.com", "rio.aroun-maxwell@arup.com"],
    //     },
    //     {
    //       title: "Emily's Global Arson",
    //       link: "https://letmegooglethat.com/?q=what+is+the+date+today",
    //       category: [],
    //       info: "This is a global solution to all the world's problems",
    //       src: "/img/toolsandworkflows.png",
    //       owner: "mitchell.harvey@arup.com",
    //     },
    //   ],
  }),

  mounted() {
    for (let x in this.cardList) {
      this.cardsInView.push(this.cardList[x]);
    }
    console.log(this.$store.state.ApiUri);
    // this.getSharePointData();
  },

  methods: {
    addTool() {
      let toolBlob = _.cloneDeep(this.selectedTool);
      toolBlob.Id = this.$uuid.v4();
      if (
        this.notifyToolOwner &&
        this.$store.state.ApiUri ==
          "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        this.sendEmail(toolBlob);
      }

      this.$emit("update:model", toolBlob);

      this.selectedTool = {
        Id: null,
        Tool: null,
        Aim: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
        Notes: null,
      };
      this.addToolDialog = false;

      this.notifyText = true;
    },

    findRecipients(tool, cardList) {
      let recipients = [];
      const { owner } = cardList.find((card) => card.title === tool) || {};

      if (typeof owner === "string") {
        return [
          {
            Name: email
              .split("@")[0]
              .split(/[ .'",\-]+/)
              .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
              .join(" "),
            Email: owner,
          },
        ];
      } else if (Array.isArray(owner)) {
        for (let email of owner) {
          recipients.push({
            Name: email
              .split("@")[0]
              .split(/[ .'",\-]+/)
              .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
              .join(" "),
            Email: email,
          });
        }
        return recipients;
      } else if (typeof owner === "object") {
        const regionMap = {
          "UKIMEA Region": "UKIMEA",
          "East Asia Region": "EA",
          Europe: "EU",
          "Americas Region": "AMS",
          "Australasia Region": "AUS",
        };
        const region =
          regionMap[this.$store.state.plan.Region] || Object.keys(owner)[0];

        if (Array.isArray(owner[region])) {
          for (let email of owner[region]) {
            recipients.push({
              Name: email
                .split("@")[0]
                .split(/[ .'",\-]+/)
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(" "),
              Email: email,
            });
          }
          return recipients;
        } else {
          return [
            {
              Name: email
                .split("@")[0]
                .split(/[ .'",\-]+/)
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(" "),
              Email: owner[region],
            },
          ];
        }
      }
    },

    debug() {},

    async sendEmail(toolData) {
      try {
        const body = {
          ProjectName: this.$store.state.plan.ProjectName,
          ProjectNumber: this.$store.state.plan.JobNumber,
          PersonResponsible: {
            Email: toolData.TeamOwner.Email,
            Name: toolData.TeamOwner.Name,
          },
          ToolName: toolData.Tool,
          UseCase: toolData.Aim,
          Recipients: this.findRecipients(toolData.Tool, this.cardList),
        };

        console.log("generating body...");
        console.log(body);

        const response = await this.$dip.post(
          `${this.$store.state.ApiUri}/SendEmail`,
          body
        );
        console.log(response.data);
      } catch (error) {
        console.log(error.response);
      }
    },

    cancel() {
      this.addToolDialog = false;
      this.notifyToolOwner = false;
      this.selectedTool = {
        Id: null,
        Tool: null,
        Aim: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
        Notes: null,
      };
    },

    createNewEntry(toolName) {
      this.addToolDialog = true;
      this.selectedTool.Tool = toolName;
    },

    // methods to filter carousel / filter methods

    filterCards(filter) {
      for (let i = this.cardsInView.length - 1; i > -1; i--) {
        if (!this.cardsInView[i].category.includes(filter)) {
          this.cardsInView.splice(i, 1);
        } else {
        }
      }
    },

    addFilter(filter, category) {
      this.resetFiltering();
      this.activeFilters[category] = filter;
      this.applyFilters();
    },

    removeFilter(category) {
      this.resetFiltering();
      this.activeFilters[category] = null;
      this.applyFilters();
    },

    applyFilters() {
      for (let x in this.activeFilters) {
        if (this.activeFilters[x] == null) {
          continue;
        } else {
          this.filterCards(this.activeFilters[x]);
        }
      }
    },

    resetFiltering() {
      this.cardsInView = _.cloneDeep(this.cardList);
      this.$refs.slider.$emit("slideTo", 0);
    },

    copyItem(item) {
      return _.cloneDeep(item);
    },
  },
};
</script>
<style></style>
;
