var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Has the client provided an Employer Information Requirements (EIR) ? ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.EIRSupplied, 'EIRSupplied', false)}},model:{value:(_vm.EIRSupplied),callback:function ($$v) {_vm.EIRSupplied=$$v},expression:"EIRSupplied"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("EIRs are commonly provided by the client and the BEP is developed as a response. The EIRs provide client aspirations for data and information exchange and management.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/teams/digital-design-development/Published%20Guidance%20Documents/Guidance%20Note%20-%20BIM%20Scope.pdf#search=EIR"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.EIRSupplied == 'true')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Please add a link ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{on:{"change":function($event){return _vm.updateStore(_vm.EIRSuppliedTrue, 'EIRSuppliedTrue', false)}},model:{value:(_vm.EIRSuppliedTrue),callback:function ($$v) {_vm.EIRSuppliedTrue=$$v},expression:"EIRSuppliedTrue"}})],1)],1)],1):_vm._e(),(_vm.EIRSupplied == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" EIR is an essential document for the successful implementation of a project. If appropriate we recommend PM to contact client to discuss Arup providing this scope on employer's behalf, but at the very least we should have a copy of this document. ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.EIRSuppliedFalse,
              'Check that the client has provided Employer Information Requirements (EIR)'
            );
            _vm.updateStore(_vm.EIRSuppliedFalse, 'EIRSuppliedFalse', true);}},model:{value:(_vm.EIRSuppliedFalse),callback:function ($$v) {_vm.EIRSuppliedFalse=$$v},expression:"EIRSuppliedFalse"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Is there a BIM execution plan for the project ? (BEP) ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.BEPSupplied, 'BEPSupplied', false)}},model:{value:(_vm.BEPSupplied),callback:function ($$v) {_vm.BEPSupplied=$$v},expression:"BEPSupplied"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Click 'Learn More' for templates and guidance.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/sites/bim/SitePages/Arup-Templates.aspx"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.BEPSupplied == 'true')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Please add a link ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{on:{"change":function($event){return _vm.updateStore(_vm.BEPSuppliedTrueA, 'BEPSuppliedTrueA', false)}},model:{value:(_vm.BEPSuppliedTrueA),callback:function ($$v) {_vm.BEPSuppliedTrueA=$$v},expression:"BEPSuppliedTrueA"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Is the BEP ISO 19650 compliant ? ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.BEPSuppliedTrueB, 'BEPSuppliedTrueB', false)}},model:{value:(_vm.BEPSuppliedTrueB),callback:function ($$v) {_vm.BEPSuppliedTrueB=$$v},expression:"BEPSuppliedTrueB"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1256888243)},[_c('span',[_vm._v("Click 'Learn More' for templates and guidance.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/sites/bim/SitePages/Arup-Templates.aspx"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.BEPSuppliedTrueB == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign an action item for this ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
                _vm.BEPSuppliedFalseB,
                'Make sure that the BEP is ISO 19650 compliant'
              );
              _vm.updateStore(_vm.BEPSuppliedFalseB, 'BEPSuppliedFalseB', true);}},model:{value:(_vm.BEPSuppliedFalseB),callback:function ($$v) {_vm.BEPSuppliedFalseB=$$v},expression:"BEPSuppliedFalseB"}})],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.BEPSupplied == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Buildings and Infrastructure projects should have BIM execution plans. A BIM execution plan should be developed. ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.BEPSuppliedFalseA,
              'A BIM execution plan should be developed'
            );
            _vm.updateStore(_vm.BEPSuppliedFalseA, 'BEPSuppliedFalseA', true);}},model:{value:(_vm.BEPSuppliedFalseA),callback:function ($$v) {_vm.BEPSuppliedFalseA=$$v},expression:"BEPSuppliedFalseA"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Are there agreed Levels of Development/Detail for each project stage? ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.LODAgreements, 'LODAgreements', false)}},model:{value:(_vm.LODAgreements),callback:function ($$v) {_vm.LODAgreements=$$v},expression:"LODAgreements"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("LOD often refers to level of development or level of detail. It is primarily used to describe the level of detail at which the consultant is expected to develop their model or information for each stage, it is important that the PM and PD are aware of the expectations of the client when signing the contract. As a brief overview, the American Institute of Architects and the NBS toolkit provide background and information of the expected LODs at each stage. Note that the AIA uses 100, 200, 300 whereas NBS uses 1, 2, 3...")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/sites/bim/SitePages/Level-of-Development.aspx"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.LODAgreements == 'true')?_c('div'):_vm._e(),(_vm.LODAgreements == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign an action item for this ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.LODAgreementsFalse,
              'Find out the agreed Levels of Development/Detail for each project stage'
            );
            _vm.updateStore(_vm.LODAgreementsFalse, 'LODAgreementsFalse', true);}},model:{value:(_vm.LODAgreementsFalse),callback:function ($$v) {_vm.LODAgreementsFalse=$$v},expression:"LODAgreementsFalse"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Are there opportunities for an OpenBIM approach? eg. IFC approach ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.OpenBIMApproach, 'OpenBIMApproach', false)}},model:{value:(_vm.OpenBIMApproach),callback:function ($$v) {_vm.OpenBIMApproach=$$v},expression:"OpenBIMApproach"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("OpenBIM is a specific term that provides us the option to use the BIM authoring tool that suits our needs rather than defaulting to an often sub-optimal solution that limts interoperability and automation. We often do not challenge on projects and therefore do not realise the opportunity. OpenBIM is an international initiative led by BuildingSmart International.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://www.buildingsmart.org/about/openbim/"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.OpenBIMApproach == 'true')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Add information ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{on:{"change":function($event){return _vm.updateStore(_vm.OpenBIMApproachTrue, 'OpenBIMApproachTrue', false)}},model:{value:(_vm.OpenBIMApproachTrue),callback:function ($$v) {_vm.OpenBIMApproachTrue=$$v},expression:"OpenBIMApproachTrue"}})],1)],1)],1):_vm._e(),(_vm.OpenBIMApproach == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign an action item for this ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.OpenBIMApproachFalse,
              'Find opportunities for an OpenBIM approach'
            );
            _vm.updateStore(_vm.OpenBIMApproachFalse, 'OpenBIMApproachFalse', true);}},model:{value:(_vm.OpenBIMApproachFalse),callback:function ($$v) {_vm.OpenBIMApproachFalse=$$v},expression:"OpenBIMApproachFalse"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Have you reviewed the BIM Maturity Measure to inform this review? ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.BIMmmReview, 'BIMmmReview', false)}},model:{value:(_vm.BIMmmReview),callback:function ($$v) {_vm.BIMmmReview=$$v},expression:"BIMmmReview"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("We recommend you use the tool to set a BIM Maturity Measure target for the project.")])]),_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"http://bimmaturitymeasure.arup.com/"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.BIMmmReview == 'true')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Add the link to the completed BIM Maturity Measure ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{on:{"change":function($event){return _vm.updateStore(_vm.BIMmmReviewTrue, 'BIMmmReviewTrue', false)}},model:{value:(_vm.BIMmmReviewTrue),callback:function ($$v) {_vm.BIMmmReviewTrue=$$v},expression:"BIMmmReviewTrue"}})],1)],1)],1):_vm._e(),(_vm.BIMmmReview == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign an action item for this ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.BIMmmReviewFalse,
              'Review the BIM Maturity Measure'
            );
            _vm.updateStore(_vm.BIMmmReviewFalse, 'BIMmmReviewFalse', true);}},model:{value:(_vm.BIMmmReviewFalse),callback:function ($$v) {_vm.BIMmmReviewFalse=$$v},expression:"BIMmmReviewFalse"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }