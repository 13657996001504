<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        What software and software systems will external people use (client,
        architect etc)? that may <strong>constrain or impact</strong> the way we
        work
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >This question is asked help the team to prepare for issues around
            integration, collaboration and sharing. This may impact the way we
            work with other parties or internally</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="2"> </v-col>
    </v-row>

    <v-form ref="form" v-model="isValid">
      <v-row>
        <v-col cols="6">
          <div class="mb-6">
            <v-select
              v-model="tempPackage.Collaborator"
              v-if="tempPackage.Collaborator !== 'Other'"
              :items="collaboratorOptions"
              label="Collaborator"
              :rules="[rules.checkPresence]"
            ></v-select>
            <v-text-field
              label="Other collaborator selected, please describe..."
              placeholder="..."
              autofocus
              v-else
              v-model="CollaboratorOther"
              :rules="[rules.checkPresence, rules.checkLength]"
            >
              <template v-slot:append>
                <v-btn plain icon @click="tempPackage.Collaborator = ''">
                  <v-icon color="#2092D3">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>

          <div class="mb-6">
            <v-select
              v-model="tempPackage.SoftwarePackage"
              v-if="tempPackage.SoftwarePackage !== 'Other'"
              label="Software package"
              :items="softwareOptions"
              :rules="[rules.checkPresence]"
            ></v-select>
            <v-text-field
              label="Other software package selected, please describe..."
              placeholder="..."
              autofocus
              v-else
              v-model="SoftwarePackageOther"
              :rules="[rules.checkPresence, rules.checkLength]"
            >
              <template v-slot:append>
                <v-btn plain icon @click="tempPackage.SoftwarePackage = ''">
                  <v-icon color="#2092D3">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>

          <div class="mb-6">
            <v-select
              v-model="tempPackage.PersonResponsible"
              :items="personnelList"
              item-text="Name"
              item-value="Email"
              return-object
              label="Person who will discover this"
              :rules="[rules.checkPresencePersonResponsible]"
            ></v-select>
          </div>
        </v-col>

        <v-col cols="6">
          <v-textarea
            label="Notes"
            v-model="tempPackage.Notes"
            filled
            no-resize
            rows="6"
            :rules="[rules.checkLength]"
          >
            <template v-slot:append-outer>
              <v-btn
                :disabled="!isValid"
                icon
                class="mt-16 pl-7"
                @click="appendSoftware(tempPackage)"
              >
                <v-icon x-large color="#2092D3"
                  >mdi-plus-circle-outline
                </v-icon>
              </v-btn>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <v-data-table
          :headers="ExternalSoftwareHeaders"
          :items="ExternalSoftwareFindings"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.Collaborator }}</td>
              <td>{{ row.item.SoftwarePackage }}</td>
              <td>{{ truncateString(row.item.Notes) }}</td>
              <td>{{ row.item.PersonResponsible.Name }}</td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    editDialog = true;
                    selectedItem = row.item;
                    newItem = copyItem(row.item);
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    confirmDeleteDialog = true;
                    selectedItem = row.item;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDialog" max-width="400">
      <v-card>
        <v-card-title class="headline"
          >Confirm external software item delete</v-card-title
        >
        <v-card-text
          >Are you sure you would like to delete this external software
          item?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteSoftware(selectedItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="600" @click:outside="close">
      <v-card>
        <v-card-title class="headline"
          >Edit external software item</v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="isEditValid">
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="newItem.Collaborator"
                  v-if="
                    collaboratorOptions.includes(newItem.Collaborator) &&
                    newItem.Collaborator !== 'Other'
                  "
                  label="Collaborator"
                  :items="collaboratorOptions"
                  :rules="[rules.checkPresence]"
                  @change="hasFormChanged = true"
                ></v-select>
                <v-text-field
                  v-else
                  placeholder="..."
                  label="Other collaborator"
                  autofocus
                  v-model="newItem.Collaborator"
                  :rules="[rules.checkPresence, rules.checkLength]"
                  @change="hasFormChanged = true"
                >
                  <template v-slot:append>
                    <v-btn
                      plain
                      icon
                      @click="newItem.Collaborator = collaboratorOptions[0]"
                    >
                      <v-icon color="#2092D3">mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="newItem.SoftwarePackage"
                  v-if="
                    softwareOptions.includes(newItem.SoftwarePackage) &&
                    newItem.SoftwarePackage !== 'Other'
                  "
                  label="Software package"
                  :items="softwareOptions"
                  :rules="[rules.checkPresence]"
                  @change="hasFormChanged = true"
                ></v-select>
                <v-text-field
                  v-else
                  placeholder="..."
                  label="Other software"
                  autofocus
                  v-model="newItem.SoftwarePackage"
                  :rules="[rules.checkPresence, rules.checkLength]"
                  @change="hasFormChanged = true"
                >
                  <template v-slot:append>
                    <v-btn
                      plain
                      icon
                      @click="newItem.SoftwarePackage = softwareOptions[0]"
                    >
                      <v-icon color="#2092D3">mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  class="my-4"
                  label="Notes"
                  filled
                  no-resize
                  rows="2"
                  v-model="newItem.Notes"
                  @change="hasFormChanged = true"
                  :rules="[rules.checkLength]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="8">
                <v-select
                  v-model="newItem.PersonResponsible"
                  :items="personnelList"
                  item-text="Name"
                  item-value="Email"
                  return-object
                  label="Person"
                  :rules="[rules.checkPresencePersonResponsible]"
                  @change="hasFormChanged = true"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-3" text @click="close()">Cancel</v-btn>

          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!(isEditValid && hasFormChanged)"
            @click="
              editDialog = false;
              editSoftware(selectedItem, newItem);
            "
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import CheckStateHelper from "./CheckStateHelper.vue";

export default {
  name: "App",
  props: {},
  components: { CheckStateHelper },

  data: () => ({
    ExternalSoftwareHeaders: [
      { text: "Collaborator", value: "Collaborator", sortable: true },
      { text: "Package", value: "SoftwarePackage", sortable: true },
      { text: "Notes", value: "Notes", sortable: true },
      { text: "User Assigned", value: "UserAssigned", sortable: true },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    collaboratorOptions: [
      "Structural Engineer",
      "Mechanical Engineer",
      "Electrical Engineer",
      "Public Health Engineer",
      "Client",
      "Architect",
      "Contractor",
      "Other",
    ],
    softwareOptions: [
      "Revit",
      "Autodesk",
      "Microstation",
      "Bentley",
      "Esri",
      "Unity",
      "GSA",
      "Tekla",
      "Etabs",
      "Other",
    ],
    tempPackage: {
      Id: null,
      Collaborator: null,
      SoftwarePackage: null,
      Notes: null,
      PersonResponsible: {
        Name: null,
        Email: null,
      },
    },
    newItem: {
      Id: null,
      Collaborator: null,
      SoftwarePackage: null,
      Notes: null,
      PersonResponsible: {
        Name: null,
        Email: null,
      },
    },
    selectedItem: {
      Id: null,
      Collaborator: null,
      SoftwarePackage: null,
      Notes: null,
      PersonResponsible: {
        Name: null,
        Email: null,
      },
    },
    // ExternalSoftwareFindings: [],
    SoftwarePackageOther: null,
    CollaboratorOther: null,
    confirmDeleteDialog: false,
    editDialog: false,
    isValid: false,
    isEditValid: false,
    hasFormChanged: false,

    rules: {
      checkPresence: (v) => !!v || "This field is required",
      checkLength: (v) =>
        v.length <= 140 || "Please enter less than 140 characters",
      checkPresencePersonResponsible: (v) =>
        !!(v.Name && v.Email) || "This field is required",
    },
  }),
  mounted() {
    // this.ExternalSoftwareFindings = this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings;
  },
  computed: {
    ExternalSoftwareFindings() {
      return this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings;
    },

    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
        this.$store.state.plan.DigitalPlan.DigitalLead;
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null) {
        group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
  },
  methods: {
    editOtherActionsList(previousItem, newItem) {
      let previousDescription = `Check Software package that ${previousItem.Collaborator} is using`;
      let newActionDescription = `Check Software package that ${newItem.Collaborator} is using`;
      let index = 0;
      // check if other actions store already has entries
      if (
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.length > 0
      ) {
        // check if this action already exists
        index =
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.findIndex(
            (x) => x.action == previousDescription
          );
        if (index > -1) {
          // if it does, edit it.
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary[
            index
          ].assignee = newItem.PersonResponsible;
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary[
            index
          ].action = `Check Software package that ${newItem.Collaborator} is using`;
        }
      }
    },

    // resetComponent(data) {
    //   data = "";
    //   console.log(data);
    // },

    debug() {
      console.log(this.tempPackage);
    },

    close() {
      this.editDialog = false;
      this.hasFormChanged = false;
    },

    updateOtherActionsList(item, actionDescription) {
      // other action already exists
      let index = 0;
      // check if other actions store already has entries
      if (
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.length > 0
      ) {
        // check if this action already exists
        index =
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.findIndex(
            (x) => x.action == actionDescription
          );
        if (index > -1) {
          // if it does, edit it.
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary[
            index
          ].assignee = item;
        } else {
          // if it doesnt, push this action to the list
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
            action: actionDescription,
            assignee: item,
            status: "Not started",
          });
        }
      } else {
        // no actions exist yet so just push it.
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
          action: actionDescription,
          assignee: item,
          status: "Not started",
        });
      }
    },
    check(newItem) {
      console.log(this.softwareOptions);
      console.log(newItem.SoftwarePackage);
      console.log(this.collaboratorOptions.includes(newItem.SoftwarePackage));
    },
    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
    copyItem(item) {
      return _.cloneDeep(item);
    },
    appendSoftware(item) {
      let newItem = _.cloneDeep(item);
      if (newItem.Collaborator == "Other") {
        newItem.Collaborator = this.CollaboratorOther;
        this.CollaboratorOther = null;
      }

      if (newItem.SoftwarePackage == "Other") {
        newItem.SoftwarePackage = this.SoftwarePackageOther;
        this.SoftwarePackageOther = null;
      }
      // create new uuid for item
      newItem.Id = this.$uuid.v4();
      console.log(newItem);
      // push into local array
      this.ExternalSoftwareFindings.push(newItem);
      // put local array in store
      this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings =
        this.ExternalSoftwareFindings;

      // for each ExternalSoftwareFindings build out action description and add to Other actions list
      this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings.forEach(
        (element) => {
          if (element.PersonResponsible.Name !== null) {
            let action = `Check Software package that ${element.Collaborator} is using`;
            this.updateOtherActionsList(element.PersonResponsible, action);
          }
        }
      );

      // reset tempPackage variable to
      this.tempPackage = {
        Id: null,
        Collaborator: null,
        SoftwarePackage: null,
        Notes: null,
        PersonResponsible: {
          Name: null,
          Email: null,
        },
      };
      this.$refs.form.reset();
    },
    deleteSoftware(item) {
      this.ExternalSoftwareFindings.splice(
        this.ExternalSoftwareFindings.findIndex((e) => e.Id == item.Id),
        1
      );
      this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings =
        this.ExternalSoftwareFindings;
      this.selectedItem = {
        Id: null,
        Collaborator: null,
        SoftwarePackage: null,
        Notes: null,
        PersonResponsible: {
          Name: null,
          Email: null,
        },
      };
    },
    editSoftware(selectedItem, newItem) {
      console.log(selectedItem);
      const index = this.ExternalSoftwareFindings.findIndex(
        (x) => x.Id == selectedItem.Id
      );
      if (index > -1) {
        if (newItem.Collaborator == "Other") {
          this.CollaboratorOther = null;
        }

        if (newItem.SoftwarePackage == "Other") {
          this.SoftwarePackageOther = null;
        }

        // add edit to other actions list here
        this.editOtherActionsList(selectedItem, newItem);

        this.ExternalSoftwareFindings[index].Collaborator =
          newItem.Collaborator;
        this.ExternalSoftwareFindings[index].SoftwarePackage =
          newItem.SoftwarePackage;
        this.ExternalSoftwareFindings[index].Notes = newItem.Notes;
        this.ExternalSoftwareFindings[index].PersonResponsible =
          newItem.PersonResponsible;

        this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings =
          this.ExternalSoftwareFindings;
        this.selectedItem = {
          Id: null,
          Collaborator: null,
          SoftwarePackage: null,
          Notes: null,
          PersonResponsible: {
            Name: null,
            Email: null,
          },
        };
        this.newItem = {
          Id: null,
          Collaborator: null,
          SoftwarePackage: null,
          Notes: null,
          PersonResponsible: {
            Name: null,
            Email: null,
          },
        };
      } else {
        console.log("error updating external software list");
      }
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>

<style src="../styles/table.scss" lang="scss"></style>
