<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Automation ideas</h2>
        <p>
          Choose from a list of the most popular automation tools and platforms
          within ARUP. Use the tag buttons to filter what is relevant to your
          project
        </p>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>

    <v-card class="pa-2 mb-10 red lighten-4">
      <AutomationCarousel @update:model="addEntryToTable" />
    </v-card>

    <v-row>
      <v-col cols="12">
        <h2>Digital Services</h2>
        <p>Find an existing digital service to incorporate into the project</p>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>

    <v-card class="pa-2 mb-10 blue lighten-4">
      <DigitalServicesCarousel @update:model="addEntryToTable" />
    </v-card>
    <v-row>
      <v-col cols="12">
        <h2>Value Stories</h2>
        <p>Find some value stories to attribute to your work</p>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>

    <v-card class="pa-2 green lighten-4">
      <v-overlay :absolute="true" color="#666">
        <v-card>
          <v-card-title class="justify-center"
            >COMING SOON<v-icon class="ml-2">mdi-hammer</v-icon></v-card-title
          >
          <v-card-text
            ><v-btn
              href="https://arup.sharepoint.com/sites/ValueStories/SitePages/Home.aspx"
              target="_blank"
              rel="noopener noreferrer"
              >Visit Value Stories Sharepoint</v-btn
            ></v-card-text
          >
        </v-card>
      </v-overlay>
      <ValueStoriesCarousel @update:model="addEntryToTable" />
    </v-card>

    <v-divider class="my-5"></v-divider>

    <v-data-table
      :headers="currentHeaders"
      :items="currentItems"
      hide-default-footer
      class="elevation-1"
      disable-pagination
      :key="render"
      ><template v-slot:top>
        <v-tabs v-model="tab" background-color="grey lighten-4">
          <v-tab>Automation Tools</v-tab>
          <v-tab>Digital Services</v-tab>
          <!-- <v-btn @click="debug()" class="blue darken-3 ml-4 my-1" dark
            >DEBUG</v-btn
          > -->
        </v-tabs>
      </template>
      <template v-slot:item="row">
        <tr>
          <td v-if="tab === 0">{{ truncateString(row.item.Tool) }}</td>
          <td v-if="tab === 1">{{ truncateString(row.item.Service) }}</td>
          <td v-if="tab === 2">{{ truncateString(row.item.Story) }}</td>

          <td>{{ truncateString(row.item.Aim) }}</td>
          <td>{{ truncateString(row.item.Notes) }}</td>
          <td v-if="row.item.TeamOwner">
            {{ truncateString(row.item.TeamOwner.Name) }}
          </td>

          <td class="tableActions">
            <v-btn
              icon
              @click="
                editDialog = true;
                selectedRowItem = row.item;
                editedRowItem = copyItem(row.item);
              "
              class="mdi-pencil"
            ></v-btn>
          </td>
          <td class="tableActions">
            <v-btn
              icon
              @click="
                deleteDialog = true;
                selectedRowItem = row.item;
              "
              class="mdi-trash-can-outline"
            >
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:footer v-if="showLegacyData">
        <v-data-table
          :headers="ArupLegacyHeaders"
          :items="legacyDataEntries"
          class="mt-1 elevation-0"
          hide-default-footer
          disable-pagination
          dense
        >
          <!-- `["Id","Name","UsefulLink","Discussion","PersonResponsible"]` -->
          <template v-slot:item="row">
            <tr v-if="row.item.Id">
              <td>{{ truncateString(row.item.Name) }}</td>
              <td>{{ truncateString(row.item.UsefulLink) }}</td>
              <td>{{ truncateString(row.item.Discussion) }}</td>
              <td>{{ truncateString(row.item.PersonResponsible.Name) }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-data-table>

    <v-row class="mt-0">
      <v-col class="pb-0 text-right">
        <v-btn
          plain
          @click="showLegacyData = !showLegacyData"
          v-if="legacyDataEntries.length > 0"
        >
          <span v-if="showLegacyData">
            <v-icon class="mr-1">mdi-arrow-up</v-icon>
            hide legacy data
          </span>
          <span v-else>
            <v-icon class="mr-1">mdi-arrow-down</v-icon>
            show legacy data
          </span>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" max-width="800px" @click:outside="cancel">
      <v-card class="removeScroll">
        <v-card-title class="headline grey lighten-2"
          >Edit {{ text }}</v-card-title
        >

        <v-container class="pa-5">
          <v-row>
            <v-col cols="6">
              <v-text-field
                disabled
                :label="'Selected ' + text"
                :value="
                  editedRowItem.Tool ||
                  editedRowItem.Service ||
                  editedRowItem.Story
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AimSelect
                v-if="editDialog"
                :model.sync="editedRowItem.Aim"
                label="Potential use case"
              />
            </v-col>
            <v-col cols="6">
              <StaffSelect
                v-if="editDialog"
                :display="true"
                :model.sync="editedRowItem.TeamOwner"
                :dialog.sync="editedRowItem.TeamOwner"
                label="Select Team Owner"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mb-10"
                filled
                no-resize
                v-model="editedRowItem.Notes"
                label="Notes (optional)"
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6" align="right">
              <v-btn text @click="cancel">cancel</v-btn>
              <v-btn
                color="blue"
                text
                :disabled="!changed"
                @click="editEntry(selectedRowItem, editedRowItem)"
                >Update {{ text }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="450" @click:outside="cancel">
      <v-card>
        <v-card-title class="headline"
          >Confirm removal of Arup resource</v-card-title
        >
        <v-card-text
          >Are you sure you would like to delete this resource?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red darken-3" text @click="cancel">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              deleteDialog = false;
              deleteEntry(selectedRowItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AutomationCarousel from "./AutomationCarousel.vue";
import ValueStoriesCarousel from "./ValueStoriesCarousel.vue";
import DigitalServicesCarousel from "./DigitalServicesCarousel.vue";

import AimSelect from "./AimSelect.vue";
import StaffSelect from "./StaffSelect.vue";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  components: {
    AutomationCarousel,
    ValueStoriesCarousel,
    DigitalServicesCarousel,
    AimSelect,
    StaffSelect,
  },

  data: () => ({
    AutomationHeaders: [
      { text: "Tool/Platform", value: "Tool", sortable: true },
      { text: "Potential Use Case", value: "Aim", sortable: false },
      { text: "Notes", value: "Notes", sortable: false },
      { text: "Person Responsible", value: "TeamOwner.Name", sortable: true },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    DigitalServicesHeaders: [
      { text: "Service", value: "Service", sortable: true },
      { text: "Potential Use Case", value: "Aim", sortable: false },
      { text: "Notes", value: "Notes", sortable: false },
      { text: "Person Responsible", value: "TeamOwner.Name", sortable: true },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    ValueStoriesHeaders: [
      { text: "Story", value: "Story", sortable: true },
      { text: "Potential Use Case", value: "Usecase", sortable: false },
      { text: "Notes", value: "Notes", sortable: false },
      { text: "Person Responsible", value: "Person", sortable: true },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],

    tab: 0,
    text: "Tool",

    currentHeaders: [],

    deleteDialog: false,
    editDialog: false,
    changed: false,
    showLegacyData: false,

    render: 0,

    editedRowItem: {
      Id: null,
      Tool: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },
    selectedRowItem: {
      Id: null,
      Tool: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },

    automationToolEntriesFlag: false,
    digitalServiceEntriesFlag: false,
    valueStoryEntriesFlag: false,
  }),

  mounted() {},

  watch: {
    editedRowItem: {
      handler() {
        if (this.editedRowItem.Id) {
          this.editCheck();
        }
      },
      deep: true,
    },
  },

  computed: {
    automationToolEntries: {
      get() {
        return this.$store.getters.automationToolEntries;
      },
      set(newValue) {
        this.$store.commit("updateArupResourceFindings", newValue);
      },
    },
    legacyDataEntries: {
      get() {
        return this.$store.getters.legacyDataEntries;
      },
    },
    digitalServiceEntries: {
      get() {
        return this.$store.getters.digitalServiceEntries;
      },
      set(newValue) {
        this.$store.commit("updateArupDigitalServices", newValue);
      },
    },
    valueStoryEntries: {
      get() {
        return this.$store.getters.valueStoryEntries;
      },
      set(newValue) {
        this.$store.commit("updateArupValueStories", newValue);
      },
    },

    currentItems() {
      if (this.tab === 0) {
        this.currentHeaders = this.AutomationHeaders;
        return this.$store.getters.automationToolEntries;
      } else if (this.tab === 1) {
        this.currentHeaders = this.DigitalServicesHeaders;
        return this.$store.getters.digitalServiceEntries;
      } else if (this.tab === 2) {
        this.currentHeaders = this.ValueStoriesHeaders;
        return this.$store.getters.valueStoryEntries;
      }
    },
  },

  methods: {
    addEntryToTable(data) {
      let parseString = JSON.stringify(Object.keys(data));

      if (parseString === `["Id","Tool","Aim","TeamOwner","Notes"]`) {
        this.automationToolEntries.push(data);
        this.automationToolEntriesFlag = true;
      } else if (parseString === `["Id","Service","Aim","TeamOwner","Notes"]`) {
        this.digitalServiceEntries.push(data);
        this.digitalServiceEntriesFlag = true;
      } else if (parseString === `["Id","Story","Aim","TeamOwner","Notes"]`) {
        this.valueStoryEntries.push(data);
        this.valueStoryEntriesFlag = true;
      }

      this.updateStore();
    },

    editEntry(selectedEntry, editedEntry) {
      const index = this.currentItems.findIndex(
        (x) => x.Id == selectedEntry.Id
      );
      if (index > -1) {
        this.currentItems[index].Aim = editedEntry.Aim;
        this.currentItems[index].TeamOwner = editedEntry.TeamOwner;
        this.currentItems[index].Notes = editedEntry.Notes;
        this.updateStore();
        this.selectedRowItem = {
          Id: null,
          Tool: null,
          Aim: null,
          TeamOwner: {
            Name: null,
            Email: null,
          },
          Notes: null,
        };
        this.editedRowItem = {
          Id: null,
          Tool: null,
          Aim: null,
          TeamOwner: {
            Name: null,
            Email: null,
          },
          Notes: null,
        };
        this.editDialog = false;
      } else {
        console.log("error updating arup entry list!");
      }
    },

    deleteEntry(item) {
      this.currentItems.splice(
        this.currentItems.findIndex((e) => e.Id == item.Id),
        1
      );

      this.updateStore();
    },

    async updateStore() {
      let digitalPlan;

      // create condtional logic to only update specific entries

      console.log(
        "this is what merging automation tools and legacy data looks like",
        [...this.automationToolEntries, this.legacyDataEntries]
      );

      // only merge legacy data if there is legacy data

      if (this.legacyDataEntries.length > 0) {
        this.$store.commit("updateArupResourceFindings", [
          ...this.automationToolEntries,
          this.legacyDataEntries,
        ]);
      } else {
        // otherwise just update automation tools
        this.$store.commit(
          "updateArupResourceFindings",
          this.automationToolEntries
        );
      }

      this.$store.commit(
        "updateArupDigitalServices",
        this.digitalServiceEntries
      );

      this.$store.commit("updateArupValueStories", this.valueStoryEntries);

      digitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ArupResourceFindings"
      );

      digitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ArupDigitalServices"
      );

      this.$store.state.plan.DigitalPlan = digitalPlan;
    },

    cancel() {
      this.editDialog = false;
      (this.editedRowItem = {
        Id: null,
        Tool: null,
        Aim: null,
        Notes: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
      }),
        (this.selectedRowItem = {
          Id: null,
          Tool: null,
          Aim: null,
          Notes: null,
          TeamOwner: {
            Name: null,
            Email: null,
          },
        });
    },

    editCheck() {
      if (
        JSON.stringify(this.editedRowItem) ==
        JSON.stringify(this.selectedRowItem)
      ) {
        this.changed = false;
      } else this.changed = true;
    },

    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },

    copyItem(item) {
      return _.cloneDeep(item);
    },

    rerender() {
      this.render += 1;
    },

    debug() {
      console.log(this.tab);
      console.log(this.currentHeaders);
      console.log("automationToolEntries", this.automationToolEntries);
      console.log("digitalServiceEntries", this.digitalServiceEntries);
      console.log("valueStoryEntries", this.valueStoryEntries);
      console.log("legacyDataEntries", this.legacyDataEntries);
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>

<style src="../styles/table.scss" lang="scss"></style>
