<template>
  <v-container>
    <v-row>
      <v-col cols="2.4">
        <v-select
          v-model="themes"
          label="Theme"
          height="10px"
          outlined
          @change="addFilter(themes, 'theme')"
          :items="[
            'Digital processes',
            'Platform',
            'Taxonomy',
            'Story library',
          ]"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('theme');
                themes = [];
              "
              >{{ item }}
            </v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="functions"
          label="Function"
          height="10px"
          chips
          outlined
          @change="addFilter(functions, 'function')"
          :items="['Data management', 'Workflow orchestration']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('function');
                functions = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="outputs"
          label="Output"
          height="10px"
          chips
          outlined
          @change="addFilter(outputs, 'output')"
          :items="['Calculations', 'Reporting']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('output');
                outputs = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="roles"
          label="Role"
          height="10px"
          chips
          outlined
          @change="addFilter(roles, 'role')"
          :items="[
            'Digital Practitioner',
            'Engineer/Designer',
            'Leader',
            'Project Manager/Director',
          ]"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('role');
                roles = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="communities"
          label="Communities"
          height="10px"
          chips
          outlined
          @change="addFilter(communities, 'community')"
          :items="['BIM']"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('community');
                communities = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>
    </v-row>

    <v-row>
      <slider
        ref="slider"
        :options="{ currentPage: 0, slidesToScroll: 4 }"
        v-if="cardsInView.length > 0"
      >
        <slideritem
          v-for="(item, index) in cardsInView"
          :key="index"
          class="mt-5 mb-16 px-2"
          :style="{
            width: '23.5%',
          }"
        >
          <v-card
            outlined
            hover
            class="d-flex flex-column"
            min-height="320px"
            min-width="100%"
          >
            <v-img height="150" :src="item.src"></v-img>

            <v-divider></v-divider>
            <p class="text-overline mb-0">
              {{ item.category[0] }}
            </p>

            <v-card-title
              class="text-sm-left py-2"
              style="word-break: normal; font-size: 0.4em"
            >
              • {{ truncateString(item.title) }}
            </v-card-title>

            <v-card-actions class="justify-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon
                    ><a
                      :href="item.link"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                      style="text-decoration: none; color: grey"
                      ><v-icon>mdi-link</v-icon></a
                    ></v-btn
                  >
                </template>
                <span>Go to website</span>
              </v-tooltip>
              <v-tooltip bottom max-width="20em">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    ></v-btn
                  >
                </template>
                <span>{{ item.info }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="createNewEntry(item.title)"
                    ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
                  ></template
                ><span>Assign</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </slideritem>
      </slider>

      <v-row v-else>
        <v-col cols="12">
          <v-card outlined min-height="20em" class="my-5">
            <div class="my-16">
              <v-img class="text-center mb-n3">
                <v-icon x-large class="text-center justify-center"
                  >mdi-exclamation</v-icon
                ></v-img
              >
              <v-card-title class="justify-center">
                <div style="color: gray">No Product Found</div>
              </v-card-title>
              <v-card-subtitle class="text-center"
                >Try changing your selected filters</v-card-subtitle
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog v-model="addStoryDialog" max-width="800px">
      <v-card class="removeScroll">
        <v-card-title class="headline grey lighten-2"
          >Add Value Story</v-card-title
        >

        <v-container class="pa-5">
          <v-row>
            <v-col cols="6">
              <v-text-field
                disabled
                label="Selected Story"
                :value="selectedStory.Story"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AimSelect
                v-if="addStoryDialog"
                :model.sync="selectedStory.Aim"
                :dialog.sync="selectedStory.Aim"
                label="Potential use case"
              />
            </v-col>
            <v-col cols="6">
              <StaffSelect
                v-if="addStoryDialog"
                :display="true"
                :model.sync="selectedStory.TeamOwner"
                :dialog.sync="selectedStory.TeamOwner"
                label="Search for Team owner"
                @selected="editTeamOwner = false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mb-10"
                filled
                no-resize
                v-model="selectedStory.Notes"
                label="Notes (optional)"
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6" align="right">
              <v-btn text @click="cancel">cancel</v-btn>
              <v-btn
                color="blue"
                text
                :disabled="
                  selectedStory.TeamOwner.Name == undefined ||
                  selectedStory.Aim == undefined
                "
                @click="addStory"
                >Add Story</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import { slider, slideritem } from "vue-concise-slider";
import StaffSelect from "./StaffSelect.vue";
import AimSelect from "./AimSelect.vue";

export default {
  props: {},
  components: { slider, slideritem, StaffSelect, AimSelect },
  data: () => ({
    addStoryDialog: false,

    selectedStory: {
      Id: null,
      Story: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },

    themes: [],
    functions: [],
    outputs: [],
    roles: [],
    communities: [],

    activeFilters: {
      theme: null,
      function: null,
      output: null,
      role: null,
      community: null,
    },

    cardsInView: [],
    cardList: [
      {
        title: "Brisbane International Cruise Terminal",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/sydney.png",
      },
      {
        title: "Tonga Multi Hazard Disaster Risk Assessment",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/tonga.png",
      },

      {
        title: "Site Photos Geospatial Dashboard",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/sitephotos.png",
      },
      {
        title: "Highways geometry capture from Earth Observation data",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/framework.png",
      },
      {
        title:
          "Parcel - Supporting and automating the land referencing process",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/parcel.png",
      },
      {
        title: "Tackling schistosomiasis together​",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/tackling.png",
      },
      {
        title:
          "Water Framework Directive (WFD) Baseline Automation on Total Design Automation (TDA) Platform",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/water.png",
      },
      {
        title:
          "Setting Science-based Targets on Carbon Reduction for Hong Kong rail operator",
        header: "",
        link: "",
        category: [],
        info: "",
        src: "/img/setting.png",
      },
    ],
  }),

  mounted() {
    for (let x in this.cardList) {
      this.cardsInView.push(this.cardList[x]);
    }
  },

  methods: {
    addStory() {
      let StoryBlob = _.cloneDeep(this.selectedStory);
      StoryBlob.Id = this.$uuid.v4();
      this.$emit("update:model", StoryBlob);
      this.selectedStory = {
        Id: null,
        Story: null,
        Aim: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
        Notes: null,
      };
      this.addStoryDialog = false;
    },

    cancel() {
      this.addStoryDialog = false;
      this.selectedStory = {
        Id: null,
        Story: null,
        Aim: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
        Notes: null,
      };
    },

    createNewEntry(StoryName) {
      this.addStoryDialog = true;
      this.selectedStory.Story = StoryName;
    },

    // methods to filter carousel / filter methods

    filterCards(filter) {
      for (let i = this.cardsInView.length - 1; i > -1; i--) {
        if (!this.cardsInView[i].category.includes(filter)) {
          this.cardsInView.splice(i, 1);
        } else {
        }
      }
    },

    addFilter(filter, category) {
      this.resetFiltering();
      this.activeFilters[category] = filter;
      this.applyFilters();
    },

    removeFilter(category) {
      this.resetFiltering();
      this.activeFilters[category] = null;
      this.applyFilters();
    },

    applyFilters() {
      for (let x in this.activeFilters) {
        if (this.activeFilters[x] == null) {
          continue;
        } else {
          this.filterCards(this.activeFilters[x]);
        }
      }
    },

    resetFiltering() {
      this.cardsInView = _.cloneDeep(this.cardList);
      this.$refs.slider.$emit("slideTo", 0);
    },

    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },

    copyItem(item) {
      return _.cloneDeep(item);
    },
  },
};
</script>
<style></style>
;
