<template>
  <v-row>
    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('0')">Workshop attendees</v-btn>
    </v-col>

    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('1')">Client Aims</v-btn>
    </v-col>

    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('2')">Team Aims</v-btn>
    </v-col>

    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('3')">Pain Points</v-btn>
    </v-col>

    <v-row justify="center">
      <v-expansion-panels accordion v-model="panels" multiple>
        <v-expansion-panel v-for="(item, i) in headers" :key="i">
          <v-expansion-panel-header v-if="hide(item)">
            {{ i + 1 }}. {{ item.name }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <component :is="item.componentName" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-row>
</template>

<script>
import ArupResources from "@/components/ArupResources.vue";
import SoftwareConstraints from "@/components/SoftwareConstraints.vue";
import DataRequirements from "@/components/DataRequirements.vue";
import DigitalLandscape from "@/components/DigitalLandscape.vue";

export default {
  props: ["expandAll"],

  components: {
    ArupResources,
    SoftwareConstraints,
    DataRequirements,
    DigitalLandscape,
  },
  data: () => ({
    panels: [],
    items: 6,
    headers: [
      { name: "Arup Resources", componentName: "ArupResources" },
      //{
      //  name: "Client and other external software constraints",
      //  componentName: "SoftwareConstraints",
      //},
      {
        name: "Data Requirements",
        componentName: "DataRequirements",
      },
      {
        name: "Digital Landscape",
        componentName: "DigitalLandscape",
        hideFn: (that) => {
          return (
            that.$store.state.plan.DigitalPlan.ProjectSpecificChoices.length > 0
          );
        },
      },
    ],
  }),
  watch: {
    expandAll: {
      handler() {
        if (this.expandAll == true) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },
  created() {},

  mounted() {
    if (this.$route.params.tab != undefined) {
      // only runs when URL has panelNumber inside
      let tab = parseInt(this.$route.params.tab);
      this.panels[0] = tab; // opens up expansion panel based on number in URL

      setTimeout(() => {
        this.$vuetify.goTo(1000, { duration: 1000 }); // scrolls down to opened component automatically
      }, 500);
    }
  },

  methods: {
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
      // console.log(this.panels)
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
    hide(item) {
      if ("hideFn" in item) {
        return item.hideFn(this);
      }
      return true;
    },

    navigate(panelNumber) {
      this.$router.push({
        path: "preparation/" + panelNumber + "", // pushes panelNumber to PreparationExpansionPanel
        component: this.$route.params.tab,
      });
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
