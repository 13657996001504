<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-select
          v-model="sector"
          label="Business/Sector"
          height="10px"
          outlined
          @change="addFilter(sector, 'sector')"
          :items="sectorSet"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('sector');
                sector = [];
              "
              >{{ item }}
            </v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="4">
        <v-select
          v-model="technology"
          label="Technology"
          height="10px"
          chips
          outlined
          @change="addFilter(technology, 'technology')"
          :items="technologySet"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('technology');
                technology = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>
      <!-- 
      <v-col cols="2.4">
        <v-select
          v-model="projectStage"
          label="Project Stage"
          height="10px"
          chips
          outlined
          @change="addFilter(projectStage, 'projectStage')"
          :items="projectStageSet"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('projectStage');
                projectStage = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col>

      <v-col cols="2.4">
        <v-select
          v-model="region"
          label="Regions"
          height="10px"
          chips
          outlined
          @change="addFilter(region, 'region')"
          :items="regionSet"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              @click:close="
                removeFilter('region');
                region = [];
              "
              >{{ item }}
            </v-chip>
          </template></v-select
        >
      </v-col> -->
    </v-row>

    <v-row>
      <slider
        ref="slider"
        :options="{ currentPage: 0, slidesToScroll: 4 }"
        v-if="cardsInView.length > 0"
      >
        <slideritem
          v-for="(item, index) in cardsInView"
          :key="index"
          class="mt-5 mb-16 px-2"
          :style="{
            width: '23.5%',
          }"
        >
          <v-card
            outlined
            hover
            class="d-flex flex-column"
            min-height="320px"
            min-width="100%"
          >
            <v-img height="150" :src="item.src"></v-img>

            <v-divider></v-divider>
            <p class="text-overline mb-0">
              {{ item.portfolio }}
            </p>

            <v-card-title
              class="justify-center py-2"
              style="word-break: normal"
            >
              {{ item.title }}
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :disabled="item.link ? false : true"
                    ><a
                      :href="item.link"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                      style="text-decoration: none; color: grey"
                      ><v-icon>mdi-link</v-icon></a
                    ></v-btn
                  >
                </template>
                <span>Go to website</span>
              </v-tooltip>
              <v-tooltip bottom max-width="20em">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    ></v-btn
                  >
                </template>
                <span>{{ item.info }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="createNewEntry(item.title)"
                    ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
                  ></template
                ><span>Assign</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </slideritem>
      </slider>

      <v-row v-else>
        <v-col cols="12">
          <v-card outlined min-height="20em" class="my-5">
            <div class="my-16">
              <v-img class="text-center mb-n3">
                <v-icon x-large class="text-center justify-center"
                  >mdi-exclamation</v-icon
                ></v-img
              >
              <v-card-title class="justify-center">
                <div style="color: gray">No Product Found</div>
              </v-card-title>
              <v-card-subtitle class="text-center"
                >Try changing your selected filters</v-card-subtitle
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog v-model="addServiceDialog" max-width="800px">
      <v-card class="removeScroll">
        <v-card-title class="headline grey lighten-2"
          >Add Digital Service</v-card-title
        >

        <v-container class="pa-5">
          <v-row>
            <v-col cols="6">
              <v-text-field
                disabled
                label="Selected service"
                :value="selectedService.Service"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AimSelect
                v-if="addServiceDialog"
                :model.sync="selectedService.Aim"
                :dialog.sync="selectedService.Aim"
                label="Potential use case"
              />
            </v-col>
            <v-col cols="6">
              <StaffSelect
                v-if="addServiceDialog"
                :display="true"
                :model.sync="selectedService.TeamOwner"
                :dialog.sync="selectedService.TeamOwner"
                label="Search for Team owner"
                @selected="editTeamOwner = false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mb-10"
                filled
                no-resize
                v-model="selectedService.Notes"
                label="Notes (optional)"
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6" align="right">
              <v-btn text @click="cancel">cancel</v-btn>
              <v-btn
                color="blue"
                text
                :disabled="
                  selectedService.TeamOwner.Name == undefined ||
                  selectedService.Aim == undefined
                "
                @click="addService"
                >Add service</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import { slider, slideritem } from "vue-concise-slider";
import StaffSelect from "./StaffSelect.vue";
import AimSelect from "./AimSelect.vue";

export default {
  props: {},
  components: { slider, slideritem, StaffSelect, AimSelect },
  data: () => ({
    // state variables

    addServiceDialog: false,

    // filter arrays

    sector: [],
    technology: [],
    projectStage: [],
    region: [],

    // cards currently in view

    cardsInView: [],

    // row item objects

    newRowItem: {
      Id: null,
      Service: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },
    selectedRowItem: {
      Id: null,
      Service: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },
    selectedService: {
      Id: null,
      Service: null,
      Aim: null,
      TeamOwner: {
        Name: null,
        Email: null,
      },
      Notes: null,
    },

    // filter categories

    sectorSet: [
      "Asset Management",
      "Aviation",
      "Bridge & Civil Structures",
      "Climate Change",
      "Digital Twin",
      "Energy",
      "Environmental Consulting",
      "Geotechnics",
      "Highways",
      "Linear Infrastructure",
      "Masterplanning and & Urban Design",
      "Programme & Project Management",
      "Property",
      "Rail",
      "Stakeholder Engagement",
      "Structures",
      "Sustainability",
      "Transport Planning",
      "Tunnels",
      "Water",
    ],
    technologySet: [
      "Advanced Analytics",
      "Agent Based Modelling",
      "Artificial Intelligence",
      "Cloud Computing",
      "Data Services",
      "Digital Advisory",
      "Digital Twin",
      "Generative Design",
      "IoT",
      "Machine Learning",
      "Real-Time Data",
      "Remote Sensing",
    ],
    projectStageSet: [
      "Operation and Maintenace",
      "Strategy",
      "Planning",
      "Outline design",
      "Detailed design",
      "...",
    ],
    regionSet: [
      "Americas",
      "Australasia",
      "East Asia",
      "Europe",
      "Global",
      "UKIMEA",
    ],

    activeFilters: {
      theme: null,
      function: null,
      output: null,
      role: null,
      community: null,
    },

    cardList: [
      {
        title: "Smart Building Services: Property Insight",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Smart-Buildings.aspx",
        portfolio: "PSI&SI",
        info: "Digital platform connecting operational data to revolutionise the way buildings are designed and operated for people and planet.​",
        src: "/img/Property Insight.jfif",
        tags: [
          "Asset Management",
          "Property",
          "Digital Twin",
          "IoT",
          "Real-Time Data",
          "Cloud Computing",
          "Data Services",
          "Advanced Analytics",
        ],
      },
      {
        title: "Property portfolio Risk & Resilience: CRISP",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Iris-+-Crisp.aspx",
        portfolio: "PSI&SI",
        info: "Providing property portfolio clients with new ways to achieve net zero targets driven by detailed analysis to support recommended asset management, investment and mitigation measures​. Integrated with other climate risk analysis services to provide an integrated assessment​.",
        src: "/img/CRISP.JPG",
        tags: [
          "Asset Management",
          "Property",
          "Sustainability",
          "Digital Advisory",
          "Cloud Computing",
          "Advanced Analytics",
        ],
      },
      {
        title: "Property portfolio Risk & Resilience:  IRIS",
        link: "https://arup.sharepoint.com/sites/Iris",
        portfolio: "PSI&SI",
        info: "Iris is a digital platform that enables multi-hazard physical risk assessments and simulation of resilience strategies’ impact to help organizations and communities act and ​invest with confidence.",
        src: "/img/IRIS.jfif",
        tags: [
          "Asset Management",
          "Property",
          "Sustainability",
          "Digital Advisory",
          "Cloud Computing",
          "Advanced Analytics",
        ],
      },
      {
        title: "Property Generative Design: inForm",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Property.aspx",
        portfolio: "PSI&SI",
        info: "This programme is focused on the development of scalable property generative design services for small and large developments.",
        src: "/img/inForm.png",
        tags: [
          "Property",
          " Masterplanning & Urban Design",
          "Advanced Analytics",
          "Cloud Computing",
          "Digital Advisory",
          "Generative Design",
        ],
      },
      {
        title: "Zero Emission Vehicles & Charging ",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/EV-Charging-Infrastructure-Forecasting.aspx",
        portfolio: "Transport",
        info: "Digital services that addresses ways to accelerate and support Zero Emission Vehicles (ZEV) adoption for decarbonization in the transportation industry. e.g. Charge4All, Charge4Fleets, ChargEVerywhere, EV Demand Forecast Model.",
        src: "/img/Zero Emission Vehicles.JPG",
        tags: [
          "Highways",
          "Transport Planning",
          "Digital Advisory",
          "Data Services",
          "Cloud Computing",
        ],
      },
      {
        title: "Transport/Digital Asset Management: AssetTrust",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Asset-Management.aspx?web=1",
        portfolio: "Transport",
        info: "Helping asset owners and managers to maximise the efficiency of their road asset and road corridor maintenance, by providing data visualization, proactive advice and interventions through a fully automated AI.",
        src: "/img/AssetTrust.JPG",
        tags: [
          "Asset Management",
          "Highways",
          "Machine Learning",
          "Cloud Computing",
          "Digital Advisory",
        ],
      },
      {
        title: "Digital Aviation",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Aviation.aspx?web=1",
        portfolio: "Transport",
        info: "Developing digital capabilities, using large scale data, responding to the new aviation market",
        src: "/img/Digital Aviation.JPG",
        tags: [
          "Aviation",
          "Data Services",
          "Real-Time Data",
          "Advanced Analytics",
        ],
      },
      {
        title: "Net Zero Carbon Infrastructure: Impacta",
        link: "", // Trying to find a link to further information - currently no link (maybe put link tbc as a hoverover or clearly grey it out)//
        portfolio: "PSI&SI",
        info: "The ambition for Impacta is to develop a digital service offering that will support carbon footprint mapping, decarbonisation pathway (Scopes 1-3) and ESG reporting for all asset owners and operators of linear transport infrastructure.",
        src: "/img/DAM.png",
        tags: [],
      },
      {
        title: "Infrastructure-Wide Digital Services: Bridges",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Bridges.aspx?web=1",
        portfolio: "Transport",
        info: "Linear & non-linear infrastructure route planning and site selection services and the development of services for tunnels and bridges",
        src: "/img/Bridges.JPG",
        tags: [
          "Highways",
          "Bridge & Civil Structures",
          "Linear Infrastructure",
        ],
      },
      {
        title: "Infrastructure-Wide Digital Services: Tunnels - Loup360",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Tunnels.aspx",
        portfolio: "Transport",
        info: "The tunnel inspection data platform​.",
        src: "/img/Tunnels.JPG",
        tags: [
          "Tunnels",
          "Linear Infrastructure",
          "Geotechnics",
          "Cloud Computing",
          "Artificial Intelligence",
          "Advanced Analytics",
          "Machine Learning",
        ],
      },
      {
        title: "Energy + Digital ",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Energy.aspx",
        portfolio: "EWR",
        info: "Offerings on pathways for transitioning to low carbon – EV city/heat decarbonisation, the hydrogen economy, and regulated utilities​.",
        src: "/img/Energy + Digital.JPG",
        tags: [
          "Artificial Intelligence",
          "Cloud Computing",
          "Digital Advisory",
          "Energy",
          "Climate Change",
          "Sustainability",
        ],
      },
      {
        title: "Digital Offshore Wind",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Offshore-Wind.aspx?web=1",
        portfolio: "EWR",
        info: "Full asset lifecycle digital solutions - wind farm siting, automated monopile design, asset life forecasting and operational optimisation",
        src: "/img/Digital Offshore Wind.JPG",
        tags: [
          "Energy",
          "Asset Management",
          "Real-Time Data",
          "Digital Advisory",
          "Cloud Computing",
          "Artificial Intelligence",
          "Advanced Analytics",
          "Machine Learning",
          "IoT",
          "Digital Twin",
        ],
      },
      {
        title: "Smart Water Asset Management",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Water-Asset-Management.aspx",
        portfolio: "EWR",
        info: "Full asset lifecycle digital solutions - planning/operation/maintenance of water infra and networks, and digital twin for asset management ",
        src: "/img/Smart Water management.JPG",
        tags: ["Water", "Asset Management", "Digital Twin"],
      },
      {
        title: "Water Digital Catchments",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Digital-Catchments.aspx",
        portfolio: "EWR",
        info: "Value-focused insights and multi-capital appraisal of investments at a catchment-scale for decision making and unlocking sustainable regeneration and resilience of catchments",
        src: "/img/Water Digital Catchments.JPG",
        tags: [
          "Water",
          "Remote Sensing",
          "Machine Learning",
          "Cloud Computing",
          "Artificial Intelligence",
          "Advanced Analytics",
          "Data Services",
        ],
      },
      {
        title: "Cities, Planning, Design: City Modelling Lab",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/City-Modelling-Lab.aspx",
        portfolio: "CPD",
        info: "Developing advisory offers e.g. using City Modelling Lab capabilities. Including transport-oriented development, planning and growth.",
        src: "/img/City Modelling Lab.jfif",
        tags: [
          "Transport Planning",
          "Masterplanning & Urban Design",
          "Artificial Intelligence",
          "Digital Twin",
          "IoT",
          "Real-Time Data",
          "Advanced Analytics",
          "Cloud Computing",
          "Data Services",
        ],
      },
      {
        title: "Digital Stakeholder Engagement",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Stakeholder-Engagement.aspx",
        portfolio: "Advisory",
        info: "Create an integrate suite of digital services to support the delivery of stakeholder engagement for our clients e.g. Virtual Engage",
        src: "/img/Stakeholder Engagement.JPG",
        tags: [
          "Stakeholder Engagement",
          "Programme & Project Management",
          "Water",
          "Tunnels",
          "Transport Planning",
          "Sustainability",
          "Rail",
          "Property",
          "Masterplanning & Urban Design",
          "Linear Infrastructure",
          "Highways",
          "Geotechnics",
          "Environmental Consulting",
          "Energy",
          "Digital Twin",
          "Bridge & Civil Structures",
          "Aviation",
          "Asset Management",
        ],
      },
      {
        title: "Digital Fire Visualisation Services",
        link: "", // Trying to find a link to further information - currently no link (maybe put link tbc as a hoverover or clearly grey it out)//
        portfolio: "Technical Services",
        info: "Visualising Fire and Evacuation analysis using game engines.",
        src: "/img/DAM.png",
        tags: [],
      },
      {
        title: "Digital Environment Assessment",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/dEIA.aspx",
        portfolio: "CSS",
        info: "Digitise all of the environmental assessments on projects (early stage to full planning), starting with Environmental Impact Assessments",
        src: "/img/Digital Environment Assessment.JPG",
        tags: [
          "Environmental Consulting",
          "Sustainability",
          "Advanced Analytics",
          "Data Services",
          "Digital Advisory",
        ],
      },
      {
        title: "Terrain Suite (One solution in a suite)",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/TerrAIn.aspx",
        portfolio: "Digital Services",
        info: "A single ‘Product of Products’ to deliver Earth Observation driven services. Built on a single architecture and data infrastructure to deliver efficiencies and speed to market. Including Land Use Digital Solution, Remote Based Sensing / Natural Twin, and Landslide Assessment. ",
        src: "/img/Terrain Suite.JPG",
        tags: [
          "Water",
          "Sustainability",
          "Masterplanning & Urban Design",
          "Environmental Consulting",
          "Advanced Analytics",
          "Artificial Intelligence",
          "Digital Advisory",
          "Remote Sensing",
          "Cloud Computing",
        ],
      },
      {
        title: "Digital Experience",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Digital-Experience.aspx?web=1",
        portfolio: "Digital Services",
        info: "Utilising user-centred design methodologies to better define user needs and create experiences that contribute to clients’ strategic goals. ",
        src: "/img/Digital Experience.JPG",
        tags: [
          "Asset Management",
          "Aviation",
          "Digital Twin",
          "Energy",
          "Environmental Consulting",
          "Geotechnics",
          "Highways",
          "Linear Infrastructure",
          "Masterplanning & Urban Design",
          "Programme & Project Management",
          "Property",
          "Rail",
          "Stakeholder Engagement",
          "Sustainability",
          "Transport Planning",
          "Tunnels",
          "Water",
          "Bridge & Civil Structures",
        ],
      },
      {
        title: "Virtual Inspection Services: Arup Inspect 3D",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Arup-Inspect-3D.aspx",
        portfolio: "Digital Services",
        info: "Scaling digital solutions for virtual asset survey, monitoring, inspection, assessment and intervention. Deployable across asset lifecycle.",
        src: "/img/Arup Inspect 3D.JPG",
        tags: [
          "Structures",
          "Property",
          "Digital Twin",
          "Programme & Project Management",
          "Stakeholder Engagement",
          "Asset Management",
          "Property",
          "Sustainability",
        ],
      },
      {
        title: "Climate Data Services",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Climate-Data-services.aspx?web=1",
        portfolio: "Digital Services",
        info: "Services to advance Arup’s commitment to sustainability by supporting clients to better understand, design and build for a net zero future",
        src: "/img/Climate Data Services.JPG",
        tags: [
          "Sustainability",
          "Climate Change",
          "Cloud Computing",
          "Advanced Analytics",
          "Data Services",
          "Digital Advisory",
        ],
      },
      {
        title: "Digital Twin",
        link: "https://arup.sharepoint.com/sites/Digital-Services/SitePages/Digital-Twin.aspx?web=1",
        portfolio: "Digital Services",
        info: "Enable the delivery of digital twins in the market. This is focussed on investing in Arup technology to support cross-sector twin delivery",
        src: "/img/Digital Twin.JPG",
        tags: [
          "Asset Management",
          "Aviation",
          "Bridge & Civil Structures",
          "Digital Twin",
          "Energy",
          "Environmental Consulting",
          "Geotechnics",
          "Highways",
          "Linear Infrastructure",
          "Masterplanning & Urban Design",
          "Programme & Project Management",
          "Property",
          "Rail",
          "Stakeholder Engagement",
          "Sustainability",
          "Transport Planning",
          "Tunnels",
          "Water",
          "Digital Twin",
          "Digital Advisory",
          "IoT",
        ],
      },
    ],
  }),

  mounted() {
    for (let x in this.cardList) {
      this.cardsInView.push(this.cardList[x]);
    }
  },

  methods: {
    addService() {
      let serviceBlob = _.cloneDeep(this.selectedService);
      serviceBlob.Id = this.$uuid.v4();

      this.$emit("update:model", serviceBlob);

      this.selectedService = {
        Id: null,
        Service: null,
        Aim: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
        Notes: null,
      };
      this.addServiceDialog = false;
    },

    cancel() {
      this.addServiceDialog = false;
      this.selectedService = {
        Id: null,
        Service: null,
        Aim: null,
        TeamOwner: {
          Name: null,
          Email: null,
        },
        Notes: null,
      };
    },

    createNewEntry(serviceName) {
      this.addServiceDialog = true;
      this.selectedService.Service = serviceName;
    },

    // methods to filter carousel / filter methods

    filterCards(filter) {
      for (let i = this.cardsInView.length - 1; i > -1; i--) {
        console.log(this.cardsInView[i].title);
        console.log(this.cardsInView[i]);
        if (!this.cardsInView[i].tags.includes(filter)) {
          console.log("card " + i + " has the filter " + filter);
          this.cardsInView.splice(i, 1);
        } else continue;
      }
    },

    addFilter(filter, category) {
      this.resetFiltering();
      this.activeFilters[category] = filter;
      this.applyFilters();
    },

    removeFilter(category) {
      this.resetFiltering();
      this.activeFilters[category] = null;
      this.applyFilters();
    },

    applyFilters() {
      for (let x in this.activeFilters) {
        if (this.activeFilters[x] == null) {
          continue;
        } else {
          this.filterCards(this.activeFilters[x]);
        }
      }
    },

    resetFiltering() {
      this.cardsInView = _.cloneDeep(this.cardList);
      this.$refs.slider.$emit("slideTo", 0);
    },

    copyItem(item) {
      return _.cloneDeep(item);
    },
  },
};
</script>
<style></style>
;
