<template>
  <v-container>
    <!-- GIS Q1 -->
    <v-row>
      <v-col cols="6">
        Has the client provided GIS / Geospatial requirements ?
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="GISRequirements"
          @change="updateStore(GISRequirements, 'GISRequirements', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Ensure you have a GIS competant person available to discuss the GIS
            related questions. See 'Learn More' for local GIS reps.</span
          >
        </v-tooltip>
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="https://arup.sharepoint.com/sites/Atlas/SitePages/Governance.aspx?web=1"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="GISRequirements == 'true'">
      <v-row>
        <v-col cols="6"> Please add a link </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="GISRequirementsTrueA"
            @change="
              updateStore(GISRequirementsTrueA, 'GISRequirementsTrueA', false)
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          Have you reviewed and understood the GIS standards required by client
          ?
        </v-col>
        <v-col cols="3">
          <v-radio-group
            v-model="GISRequirementsTrueB"
            @change="
              updateStore(GISRequirementsTrueB, 'GISRequirementsTrueB', false)
            "
          >
            <v-radio label="Yes" value="true"></v-radio>
            <v-radio label="No or not sure" value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="3">
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span
              >From a planning, resource and cost aspect it is important to
              appreciate the significant impact the selection of a standard can
              have on the amount of work to achieve the GIS deliverables.</span
            >
          </v-tooltip>
          <v-btn
            color="grey lighten-2"
            dark
            elevation="0"
            class="grey--text"
            rounded
            small
            target="_blank"
            href="https://arup.sharepoint.com/sites/DSN_DTC/SitePages/Geospatial-Standards.aspx/"
          >
            LEARN MORE
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="GISRequirementsTrueB == 'true'">
        <v-row>
          <v-col cols="6"> Select Type </v-col>
          <v-col cols="6">
            <v-select
              v-model="GISRequirementsTrueBTrue"
              :items="['ISO', 'Client', 'Arup']"
              label=""
              @change="
                updateStore(
                  GISRequirementsTrueBTrue,
                  'GISRequirementsTrueBTrue',
                  false
                )
              "
            ></v-select>
          </v-col>
        </v-row>
      </div>

      <div v-if="GISRequirementsTrueB == 'false'">
        <v-row>
          <v-col cols="6"> Assign an action to agree </v-col>
          <v-col cols="6">
            <v-select
              v-model="GISRequirementsTrueBFalse"
              :items="personnelList"
              item-text="Name"
              item-value="Email"
              return-object
              label="Please select..."
              @change="
                updateOtherActionsList(
                  GISRequirementsTrueBFalse,
                  'Review the GIS standards required by the client'
                );
                updateStore(
                  GISRequirementsTrueBFalse,
                  'GISRequirementsTrueBFalse',
                  true
                );
              "
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-if="GISRequirements == 'false'">
      <v-row>
        <v-col cols="6">
          Assign a team member to speak to the client to scope out requirements
        </v-col>
        <v-col cols="5">
          <v-select
            v-model="GISRequirementsFalse"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                GISRequirementsFalse,
                'Speak with client to scope GIS / Geospatial requirements'
              );
              updateStore(GISRequirementsFalse, 'GISRequirementsFalse', true);
            "
          ></v-select>
        </v-col>

        <v-col cols="1">
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span
              >This person would ideally be the Digital Facilitator or someone
              who understands GIS.</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {},

  data: () => ({}),
  mounted() {},
  watch: {},
  computed: {
    GISRequirements: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.GISRequirements == true ||
          this.$store.state.plan.DigitalPlan.GISRequirements == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.GISRequirements == false ||
          this.$store.state.plan.DigitalPlan.GISRequirements == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.GISRequirements = val;
      },
    },
    GISRequirementsTrueA: {
      get() {
        return this.$store.state.plan.DigitalPlan.GISRequirementsTrueA;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.GISRequirementsTrueA = val;
      },
    },
    GISRequirementsFalse: {
      get() {
        return this.$store.state.plan.DigitalPlan.GISRequirementsFalse;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.GISRequirementsFalse = val;
      },
    },
    GISRequirementsTrueB: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.GISRequirementsTrueB == true ||
          this.$store.state.plan.DigitalPlan.GISRequirementsTrueB == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.GISRequirementsTrueB == false ||
          this.$store.state.plan.DigitalPlan.GISRequirementsTrueB == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.GISRequirementsTrueB = val;
      },
    },
    GISRequirementsTrueBFalse: {
      get() {
        return this.$store.state.plan.DigitalPlan.GISRequirementsTrueBFalse;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.GISRequirementsTrueBFalse = val;
      },
    },
    GISRequirementsTrueBTrue: {
      get() {
        return this.$store.state.plan.DigitalPlan.GISRequirementsTrueBTrue;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.GISRequirementsTrueBTrue = val;
      },
    },
    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
        this.$store.state.plan.DigitalPlan.DigitalLead;
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null) {
        group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
  },
  methods: {
    async updateStore(newVal, property, actionListFlag) {
      this.$store.state.plan.DigitalPlan[property] = newVal;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        property
      );
    },
    async updateOtherActionsList(item, actionDescription) {
      // other action already exists
      let index = 0;
      // check if other actions store already has entries
      if (
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.length > 0
      ) {
        // check if this action already exists
        index =
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.findIndex(
            (x) => x.action == actionDescription
          );
        if (index > -1) {
          // if it does, edit it.
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary[
            index
          ].assignee = item;
        } else {
          // if it doesnt, push this action to the list
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
            action: actionDescription,
            assignee: item,
            status: "Not started",
          });
        }
      } else {
        // no actions exist yet so just push it.
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
          action: actionDescription,
          assignee: item,
          status: "Not started",
        });
      }
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "OtherActionsListSummary"
      );
    },
  },
};
</script>

<style scoped></style>
