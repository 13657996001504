<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <p>
          Do you know what data sets you will be be working with for this
          project?
        </p>
        <v-radio-group class="mt-n1" v-model="DatasetStatus">
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No or not sure" :value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="6">
        <v-row class="ml-1">
          <v-btn
            color="grey lighten-2"
            dark
            elevation="0"
            class="grey--text mr-15"
            rounded
            small
            target="_blank"
            href="https://arup.sharepoint.com/sites/essentials-global-digital-technology/SitePages/Project-Storage-Locations.aspx?xsdata=MDV8MDF8fGZjOTgxNGI5OTJkMDQ4Y2IxNGM0MDhkYmI5MTJmMGU0fDRhZTQ4YjQxMDEzNzQ1OTk4NjYxZmM2NDFmZTc3YmVhfDB8MHw2MzgzMDcyNjMyMDUwODY0MDR8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vYVdRaUxDSkhOeTg5TXpNeU1qYzFMbUo0TWpjNE5EWTBORGMwTVdFME16TTJMVGczWWpObE1tVmpaVEkwWWpBeVlUQm1NekF5WmpSaE1tUmhaakEwWkRWbE5qTXhNemMyT0RFMVlXUmpOa1k0WmpVNU9EUTRZelV6TkRBd1ltUXhOVGxsTWpJd09Ea3dNREF4TWpJd05qVXpNVFUwTURZNU5qRXdNalF3TXpNd01qQXhPVEE1T1RVNU1qVTFOZz09fDQwNjVlN2EyLTQ1OTMtNDU5OS04NjYxLWZjNjQxZmU3N2JlYXwxNjk1MTI5ODc4NDQ2&ovuser=4ae48b41-0137-4599-8661-fc641fe77bea%2CRio.Aroun-Maxwell%40arup.com&OR=Teams-HL&CT=1695129878446&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA4MDQwODYzNiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
          >
            LEARN MORE
          </v-btn>
          <v-tooltip right max-width="500">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-n12" color="gray"
                >mdi-information-outline</v-icon
              >
            </template>
            <span>
              Dataset = A collection of data that is composed of separate
              entities. <br />
              For example a project schedule is comprised of a dataset which
              contains entities such as milestones, resources, due dates, etc...
            </span>
          </v-tooltip>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="DatasetStatus == false">
            <p align="left" class="ml-2">
              Having a clear understanding of the data sets you will be working
              with is important to ensure you have the right people, processes
              and tools in place to manage data effectively.
            </p>
            <v-select
              v-model="DatasetContact"
              :items="personnelList"
              item-text="Name"
              item-value="Email"
              return-object
              label="Select point of contact..."
              @change="(v) => (DatasetContact = v)"
            />
          </v-col>
          <v-col cols="12" v-else></v-col>
          <!--display nothing-->
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col cols="12">
        <v-form v-model="areDatafieldsValid" ref="datafields">
          <v-row v-if="DatasetStatus == true" class="mb-2 mr-10">
            <v-col cols="4">
              <v-select
                class="mx-2"
                v-model="tempRequirement.Type"
                v-if="tempRequirement.Type !== 'Other'"
                :items="dataTypeOption"
                label="Data type"
                :rules="vSelectRules"
              ></v-select>
              <v-text-field
                class="mx-2"
                label="Other type selected, please describe..."
                v-else
                v-model="DataTypeOther"
                :rules="vTextFieldRules"
              >
                <template v-slot:append>
                  <v-btn @click="tempRequirement.Type = null" icon>
                    <v-icon color="blue">mdi-arrow-left</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                class="mx-2"
                v-model="tempRequirement.Location"
                v-if="tempRequirement.Location !== 'Other'"
                :items="locationOption"
                label="Storage location"
                :rules="vSelectRules"
              ></v-select>
              <v-text-field
                class="mx-2"
                label="Other location selected, please describe..."
                v-else
                v-model="LocationOther"
                :rules="vTextFieldRules"
              >
                <template v-slot:append>
                  <v-btn @click="tempRequirement.Location = null" icon>
                    <v-icon color="blue">mdi-arrow-left</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="4">
              <StaffSelect
                class="mx-2"
                :display="true"
                :model.sync="tempRequirement.Contact"
                :dialog.sync="tempRequirement.Contact"
                @update:model="
                  tempRequirement.Contact;
                  reset = false;
                "
                :reset="reset"
                label="Select Key point of contact"
              />
            </v-col>
          </v-row>
          <v-row v-else></v-row>
          <!--display nothing-->
        </v-form>
      </v-col>
    </v-row>

    <v-row v-if="DatasetStatus == true">
      <v-col cols="12">
        <v-textarea
          v-model="tempRequirement.Notes"
          rows="2"
          filled
          outlined
          no-resize
          label="Please type in any additional notes here..."
        >
          <template v-slot:append-outer>
            <v-btn
              icon
              class="pb-2 pl-3"
              :disabled="!areDatafieldsValid"
              @click="appendRequirement(tempRequirement)"
            >
              <v-icon x-large color="#2092D3">mdi-plus-circle-outline </v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </v-col>
      <v-col cols="1"> </v-col>
    </v-row>

    <v-row v-else></v-row>
    <!--display nothing-->

    <v-row v-if="DatasetStatus == true">
      <v-col>
        <v-data-table
          :headers="DataRequirementHeaders"
          :items="DataRequirements"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.Type }}</td>
              <td>{{ row.item.Location }}</td>
              <td>{{ truncateString(row.item.Notes) }}</td>
              <td>{{ row.item.Contact["Name"] }}</td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    editDialog = true;
                    selectedItem = row.item;
                    newItem = copyItem(row.item);
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    confirmDeleteDialog = true;
                    selectedItem = row.item;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row v-else></v-row>

    <v-dialog v-model="confirmDeleteDialog" max-width="600">
      <v-card>
        <v-card-title class="headline" style="white-space: nowrap"
          >Confirm data requirement item delete</v-card-title
        >
        <v-card-text
          >Are you sure you would like to delete this data requirement
          item?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteRequirement(selectedItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Edit data requirement item</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="newItem.Type"
                v-if="
                  dataTypeOption.includes(newItem.Type) &&
                  newItem.Type !== 'Other'
                "
                :items="dataTypeOption"
                label="Data type"
              ></v-select>
              <v-text-field
                label="Describe data type..."
                v-else
                v-model="newItem.Type"
              >
                <template #append>
                  <v-btn
                    :ripple="false"
                    icon
                    plain
                    color="blue lighten-2"
                    class="pl-7"
                    @click="newItem.Type = dataTypeOption[0]"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="newItem.Location"
                v-if="
                  locationOption.includes(newItem.Location) &&
                  newItem.Location !== 'Other'
                "
                :items="locationOption"
                label="Storage location"
              ></v-select>
              <v-text-field
                label="Other location selected, please describe..."
                v-else
                v-model="newItem.Location"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <StaffSelect
                :display="true"
                :model.sync="newItem.Contact"
                :dialog.sync="newItem.Contact"
                @update:model="
                  newItem.Contact;
                  reset = false;
                "
                :reset="reset"
                label="Select Key point of contact"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Notes"
                rows="2"
                filled
                outlined
                no-resize
                :value="newItem.Notes"
                @change="(v) => (newItem.Notes = v)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-3" text @click="editDialog = false"
            >Cancel</v-btn
          >

          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="
              editDialog = false;
              editRequirement(selectedItem, newItem);
            "
            :disabled="newItem.Type == null || newItem.Type == ''"
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-divider class="my-10"></v-divider>

    <v-row>
      <v-col cols="6">
        <p>
          Have you identified metadata standards and documented the relevant
          metadata? <br />
          (See Learn More for the template)
        </p>

        <v-radio-group class="mt-n1" v-model="MetadataStatus">
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No or not sure" :value="false"></v-radio>
        </v-radio-group>
        <!-- <v-btn @click="debug">debug</v-btn> -->
      </v-col>

      <v-col cols="6" class="text-left mt-4">
        <div class="mb-5 mt-n5" ref="information">
          <v-btn
            color="grey lighten-2"
            dark
            elevation="0"
            class="grey--text mr-15"
            rounded
            small
            target="_blank"
            href="https://arup.sharepoint.com/sites/essentials-global-digital-technology/SitePages/PDE-Project_Metadata.aspx"
          >
            LEARN MORE</v-btn
          >
          <v-tooltip right max-width="500">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-n12" color="gray"
                >mdi-information-outline</v-icon
              >
            </template>
            <span>
              Metadata = Data that defines and describes the characteristics of
              other data. <br />
              It is used to improve understanding of the data and related
              processes. <br />
              For example, data contained within a title block on an engineering
              drawing such as author, date, last updated, etc...
            </span>
          </v-tooltip>
        </div>

        <div v-if="MetadataStatus == true">
          <p>
            Ensure there is an individual in place to regularly check the
            metdata standards are followed.
          </p>
          <v-textarea
            class="mb-10"
            no-resize
            filled
            rows="3"
            placeholder="Please document the metadata standards that are being followed"
            @blur="updateMetadataStandards"
            v-model="MetadataStandards"
          >
          </v-textarea>
        </div>

        <div v-if="MetadataStatus == false">
          <p align="left" class="ml-2">
            Whilst identifying metadata standards and documenting metadata is
            best practice it may not always be necessary. Please discuss within
            your team what metadata is necessary to provide understanding of the
            data.
          </p>

          <v-select
            v-model="MetadataContact"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Select point of contact..."
            @change="(v) => (MetadataContact = v)"
          />
        </div>
        <div v-else></div>
        <!--display nothing-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";
import StaffSelect from "./StaffSelect.vue";

export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {
    StaffSelect,
  },

  data: () => ({
    DataRequirementHeaders: [
      { text: "Data Type", value: "Type", sortable: true },
      { text: "Location", value: "Location", sortable: true },
      { text: "Notes", value: "Notes", sortable: true },
      { text: "Key Contact", value: "Contact", sortable: true },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    dataTypeOption: [
      "Models",
      "Internal Documents",
      "External Documents",
      "Communication",
      "Other",
    ],
    locationOption: [
      "Projectwise",
      "Sharepoint",
      "BIM360",
      "J-Drive",
      "DDB",
      "Other",
    ],

    vSelectRules: [(v) => !!v || "This field is required"],
    vTextFieldRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 60 || "Max 60 characters",
    ],

    reset: false,
    areDatafieldsValid: false,

    LocationOther: null,
    DataTypeOther: null,
    tempRequirement: {
      Id: null,
      Type: null,
      Location: null,
      Notes: null,
      Contact: {
        Name: null,
        Email: null,
      },
    },
    newItem: {
      Id: null,
      Type: null,
      Location: null,
      Notes: null,
      Contact: {
        Name: null,
        Email: null,
      },
    },
    confirmDeleteDialog: false,
    editDialog: false,
    selectedItem: {
      Id: null,
      Type: null,
      Location: null,
      Notes: null,
    },
  }),

  mounted() {},

  computed: {
    DataRequirements: {
      get() {
        return this.$store.state.plan.DigitalPlan.DataRequirements;
      },
      set(value) {
        this.$store.state.plan.DigitalPlan.DataRequirements = value;
      },
    },

    DatasetStatus: {
      get() {
        return this.$store.getters.getDatasetStatus;
      },
      async set(value) {
        this.$store.commit("updateDatasetStatus", value);
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DatasetStatus"
        );
      },
    },

    DatasetContact: {
      get() {
        return this.$store.getters.getDatasetContact;
      },
      async set(value) {
        this.$store.commit("updateDatasetContact", value);
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DatasetContact"
        );
      },
    },

    MetadataStatus: {
      get() {
        return this.$store.getters.getMetadataStatus;
      },
      async set(value) {
        this.$store.commit("updateMetadataStatus", value);
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "MetadataStatus"
        );
      },
    },

    MetadataStandards: {
      get() {
        return this.$store.getters.getMetadataStandards;
      },
      async set(value) {
        this.$store.commit("updateMetadataStandards", value);
      },
    },

    MetadataContact: {
      get() {
        return this.$store.getters.getMetadataContact;
      },
      async set(value) {
        this.$store.commit("updateMetadataContact", value);
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "MetadataContact"
        );
      },
    },

    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
        this.$store.state.plan.DigitalPlan.DigitalLead;
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null) {
        group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
  },

  beforeDestroy() {
    console.log("beforeDestroy running...");

    if (this.DatasetStatus == true && this.DataRequirements.length < 1) {
      this.$store.commit("updateDatasetStatus", null);
    } else if (this.DatasetStatus == false) {
      this.DataRequirements = [];
    }

    if (this.MetadataStatus == true && !this.MetadataStandards) {
      this.$store.commit("updateMetadataStatus", null);
    } else if (this.MetadataStatus == false) {
      this.MetadataStandards = null;
    }
  },

  methods: {
    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
    copyItem(item) {
      return _.cloneDeep(item);
    },

    async updateMetadataStandards() {
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "MetadataStandards"
      );
    },

    async appendRequirement(item) {
      // copy item
      // let newItem = Object.create(item);
      let newItem = _.cloneDeep(item);
      if (newItem.Type == "Other") {
        newItem.Type = this.DataTypeOther;
        this.DataTypeOther = null;
      }

      if (newItem.Location == "Other") {
        newItem.Location = this.LocationOther;
        this.LocationOther = null;
      }
      // create new uuid for item
      newItem.Id = this.$uuid.v4();
      // push into local array
      this.DataRequirements.push(newItem);
      // put local array in store
      this.$store.state.plan.DigitalPlan.DataRequirements =
        this.DataRequirements;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "DataRequirements"
      );
      // reset tempPackage variable to
      this.tempRequirement = {
        Id: null,
        Type: null,
        Location: null,
        Notes: null,
        Contact: {
          Name: null,
          Email: null,
        },
      };
      this.reset = true; // wipe localModel data held in StaffSelect.vue
      this.$refs.datafields.resetValidation();
    },
    async deleteRequirement(item) {
      this.DataRequirements.splice(
        this.DataRequirements.findIndex((e) => e.Id == item.Id),
        1
      );
      this.$store.state.plan.DigitalPlan.DataRequirements =
        this.DataRequirements;

      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "DataRequirements"
      );
      this.selectedItem = {
        Id: null,
        Type: null,
        Location: null,
        Notes: null,
      };
    },
    async editRequirement(selectedItem, newItem) {
      const index = this.DataRequirements.findIndex(
        (x) => x.Id == selectedItem.Id
      );
      if (index > -1) {
        if (newItem.Location == "Other") {
          this.LocationOther = null;
        }

        if (newItem.Type == "Other") {
          this.DataTypeOther = null;
        }

        this.DataRequirements[index].Id = newItem.Id;
        this.DataRequirements[index].Type = newItem.Type;
        this.DataRequirements[index].Location = newItem.Location;
        this.DataRequirements[index].Notes = newItem.Notes;
        this.DataRequirements[index].Contact = newItem.Contact;

        this.$store.state.plan.DigitalPlan.DataRequirements =
          this.DataRequirements;

        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DataRequirements"
        );

        this.selectedItem = {
          Id: null,
          Type: null,
          Location: null,
          Notes: null,
          Contact: {
            Name: null,
            Email: null,
          },
        };
        this.newItem = {
          Id: null,
          Type: null,
          Location: null,
          Notes: null,
          Contact: {
            Name: null,
            Email: null,
          },
        };
      } else {
        console.log("error updating data requirements list");
      }
    },
    validationCheck(keyContact) {
      if (this.areDatafieldsValid && keyContact) {
        return true;
      } else {
        return false;
      }
    },
    isObjectFalsy(obj) {
      for (const key in obj) {
        if (obj[key] !== null) {
          return false;
        }
      }
      return true;
    },
    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
    copyItem(item) {
      return _.cloneDeep(item);
    },
    debug() {
      console.log(this.MetadataStandards);
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>

<style src="../styles/table.scss" lang="scss"></style>
